import React from 'react';
//import ReactDOM from 'react-dom';
import { render } from "react-dom";

import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import { Provider } from "react-redux";
import { getStore } from "./store";
import { QueryProvider } from './components/QueryContext';

//import * as serviceWorker from './serviceWorker';

import './common/css/font-awesome.min.css';

import Home from './components/Home/Home';
import Adminprofile from './components/Account/Adminprofile';
import Login from './components/Account/Login';
import Logout from "./components/Account/Logout";
import Setting from "./components/Account/Setting";
import Changepassword from "./components/Account/Changepassword";
import Forgetpassword from "./components/Account/Forgetpassword";
import Resetpassword from "./components/Account/Resetpassword";
import Company from "./components/Company/List";
import CompanyAdd from "./components/Company/Add"
import CompanyEdit from "./components/Company/Edit";



import UserList from "./components/User/List";
import UserEdit from "./components/User/Edit";
import UserAdd from "./components/User/Add";
import UserView from "./components/User/View";
import UserViewEHR from "./components/User/ViewEHR";
import UserImport from "./components/User/ImportUser";
// import ViewEHR from "./components/User/viewEHR";


import DoctorList from "./components/Doctor/List";
import DoctorEdit from "./components/Doctor/Edit";
import DoctorAdd from "./components/Doctor/Add";
import DoctorView from "./components/Doctor/View";
import DoctorImport from "./components/Doctor/ImportUser";


import Fontlist from "./components/Fonts/List";
import FontAdd from "./components/Fonts/Add";
import FontEdit from "./components/Fonts/Edit";
import FontView from "./components/Fonts/View";



import TemplateList from './components/Templates/List';
import TemplateAdd from './components/Templates/Add';
import TemplateEdit from './components/Templates/Edit';

import PaymentHistoryList from "./components/PaymentHistory/List";
import PaymentHistoryView from "./components/PaymentHistory/View";

import DashboardList from "./components/Dashboard/List";

import CmsList from "./components/CMS/List";
import CmsAdd from "./components/CMS/Add";
import CmsEdit from "./components/CMS/Edit";


import SpecialistList from "./components/Specialist/List";
import SpecialistAdd from "./components/Specialist/Add";
import SpecialistEdit from "./components/Specialist/Edit";
import SpecialistView from "./components/Specialist/View";


import ProductList from "./components/Product/List";
import ProductAdd from "./components/Product/Add";
import ProductEdit from "./components/Product/Edit";
import ProductView from "./components/Product/View";

import PackageList from "./components/Package/List";
import PackageAdd from "./components/Package/Add";
import PackageEdit from "./components/Package/Edit";



import PaymentHistorycompletedList from "./components/PaymentHistory/List";
import PaymentHistorycompletedView from "./components/PaymentHistory/View";


import ProductList_outofstock from "./components/Product/List";
import ProductEdit_outofstock from "./components/Product/Edit";
import ProductView_outofstock from "./components/Product/View";

import Cmscontent from './components/Account/Cmscontent';

import FormEntriesList from "./components/Formentries/List";
import FormEntriesView from "./components/Formentries/View";

import Couponlist from "./components/Coupons/List";
import CouponAdd from "./components/Coupons/Add";
import CouponEdit from "./components/Coupons/Edit";

import CategoryList from "./components/Category/List";
import CategoryEdit from "./components/Category/Edit";
import CategoryAdd from "./components/Category/Add";
import CategoryView from "./components/Category/View";

import AppointmentHistoryList from "./components/AppointmentHistory/List";
import AppointmentHistoryView from "./components/AppointmentHistory/View";

import FamilyRelationList from "./components/FamilyRelation/List";
import FamilyRelationAdd from "./components/FamilyRelation/Add";
import FamilyRelationView from "./components/FamilyRelation/View";
import FamilyRelationEdit from "./components/FamilyRelation/Edit";

import PrescriptionList from "./components/Prescription/List";
import PrescriptionAdd from "./components/Prescription/Add";
import PrescriptionView from "./components/Prescription/View";
import PrescriptionEdit from "./components/Prescription/Edit";

import ProductCategoryList from "./components/ProductCategory/List";
import ProductCategoryEdit from "./components/ProductCategory/Edit";
import ProductCategoryAdd from "./components/ProductCategory/Add";
import ProductCategoryView from "./components/ProductCategory/View";

import ProductBookingHistoryList from "./components/ProductBookingHistory/List";
import ProductBookingHistoryView from "./components/ProductBookingHistory/View";

import CompanyClone from "./components/Company/CompanyClone";

import DoctorVisiteBookingList from "./components/DoctorVisite/List";
import DoctorAssign from "./components/DoctorVisite/DoctorAssign";

import InsuranceList from "./components/Insurance/List";
import InsuranceAdd from "./components/Insurance/Add";
import InsuranceEdit from "./components/Insurance/Edit";
import InsuranceView from "./components/Insurance/View";

import InsuranceCategoryList from "./components/InsuranceCategory/List";
import InsuranceCategoryAdd from "./components/InsuranceCategory/Add";
import InsuranceCategoryEdit from "./components/InsuranceCategory/Edit";

import SubscribersList from "./components/Subscribers/List";
import SubscribersView from "./components/Subscribers/View";

import FaqCategoryList from "./components/FaqCategory/List";
import FaqCategoryAdd from "./components/FaqCategory/Add";
import FaqCategoryEdit from "./components/FaqCategory/Edit";
import FaqCategoryView from "./components/FaqCategory/View";

import FaqList from "./components/Faq/List";
import FaqAdd from "./components/Faq/Add";
import FaqEdit from "./components/Faq/Edit";
import FaqView from "./components/Faq/View";
import FaqImport from "./components/Faq/ImportFaq";

import AiFeedList from "./components/AiFeed/List";

import LocationList from "./components/Location/List";
import LocationEdit from "./components/Location/Edit";
import LocationAdd from "./components/Location/Add";
import LocationView from "./components/Location/View";

import GuideList from "./components/Guide/List";
import GuideEdit from "./components/Guide/Edit";
import GuideAdd from "./components/Guide/Add";
import GuideView from "./components/Guide/View";

import AIImport from "./components/AiFeed/Import";


import OutofstockList from "./components/Outofstock/List";
import OutofstockView from "./components/Outofstock/View";


import InviteDiscountList from "./components/InviteDiscount/List";
import InviteDiscountAdd from "./components/InviteDiscount/Add";
import InviteDiscountEdit from "./components/InviteDiscount/Edit";

import ReferalUserList from "./components/ReferalUser/List";
import ReferalUserView from "./components/ReferalUser/View";

import List from "./components/Providerinventory/List";
import Add from "./components/Providerinventory/Add";
import Edit from "./components/Providerinventory/Edit";


import RefillList from "./components/Requestinventory/List";
import RefillAdd from "./components/Requestinventory/Add";
import RefillEdit from "./components/Requestinventory/Edit";

import DashboardManagement from "./components/DashboardManagement/List";
import DashboardAdd from "./components/DashboardManagement/Add";
import DashboardEdit from "./components/DashboardManagement/Edit";

import RoleList from "./components/Rolemanagement/List";
import RoleEdit from "./components/Rolemanagement/Edit";
import RoleAdd from './components/Rolemanagement/Add';

import UserRoleList from "./components/UserRolemanagement/List";
import UserRoleEdit from "./components/UserRolemanagement/Edit";
import UserRoleAdd from "./components/UserRolemanagement/Add";
import UserRoleView from "./components/UserRolemanagement/View";

import AdvertisementList from "./components/Advertisement/List";
import AdvertisementEdit from "./components/Advertisement/Edit";
import AdvertisementAdd from "./components/Advertisement/Add";
// import UserRoleView from "./components/Advertisement/View";

import AdvertisementCategoryList from "./components/AdvertisementCategory/List";
import AdvertisementCategoryEdit from "./components/AdvertisementCategory/Edit";
import AdvertisementCategoryAdd from "./components/AdvertisementCategory/Add";

import ProviderRevenue from "./components/ProviderRevenue/List";
import ProviderRevenueView from "./components/ProviderRevenue/View";

import './common/css/custom.css';

/*ReactDOM.render(
		<Router>
			<Switch>
				<Route exact path='/' component={Login}/>
				<Route path="/home" component={Home} />
				
			</Switch>
		</Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();*/

const store = getStore();
render(
  <Provider store={store}>
    <Router>
    <QueryProvider>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/profile" component={Adminprofile} />
        <Route path="/logout" component={Logout} />
        <Route path="/setting" component={Setting} />
        <Route path="/changepassword" component={Changepassword} />
        <Route path="/forget_password" component={Forgetpassword} />
        <Route path="/reset_password/:key" component={Resetpassword} />
        <Route path="/company" component={Company} />
        <Route path="/company-add" component={CompanyAdd} />
        <Route path="/company-edit/:ID" component={CompanyEdit} />
        <Route path="/font-edit/:ID" component={FontEdit} />
        <Route path="/font-view/:ID" component={FontView} />
        <Route path="/fonts" component={Fontlist} />
        <Route path="/font-add" component={FontAdd} />

		    <Route path="/user" component={UserList} />
        <Route path="/edit/:ID" component={UserEdit} />
        <Route path="/view/:ID" component={UserView} />
        <Route path="/user-add" component={UserAdd} />
        <Route path="/user-import" component={UserImport} />
        <Route path="/viewEHR/:ID" component={UserViewEHR} />
        {/* <Route path="/viewEHR/:ID" component={ViewEHR} /> */}

        <Route path="/doctors" component={DoctorList} />
        <Route path="/doctor-edit/:ID" component={DoctorEdit} />
        <Route path="/doctor-view/:ID" component={DoctorView} />
        <Route path="/doctor-add" component={DoctorAdd} />
        <Route path="/doctor-import" component={DoctorImport} />

        <Route path="/templates" component={TemplateList} />
        <Route path="/template/add" component={TemplateAdd} />
        <Route path="/templates-edit/:ID" component={TemplateEdit} />
        <Route path="/payment-history" component={PaymentHistoryList} />
        <Route path="/payment-view/:ID" component={PaymentHistoryView} />
        <Route path="/offers-edit/:ID" component={CmsEdit} />
        <Route path="/offers" component={CmsList} />
        <Route path="/offers-add" component={CmsAdd} />

        <Route path="/dashboard" component={DashboardList} />


        <Route path="/specialist" component={SpecialistList} />
        <Route path="/specialist-add" component={SpecialistAdd} />
        <Route path="/specialist-edit/:ID" component={SpecialistEdit} />
        <Route path="/specialist-view/:ID" component={SpecialistView} />

    
        <Route path="/package/edit/:ID" component={PackageEdit} />
        <Route path="/packages" component={PackageList} />
        <Route path="/package-add" component={PackageAdd} />


        <Route path="/payment-completed-history" component={PaymentHistorycompletedList} />
        <Route path="/payment-completed-view/:ID" component={PaymentHistorycompletedView} />


        <Route path="/dashboardmanagement/edit/:ID" component={DashboardEdit} />
        <Route path="/dashboardmanagement" component={DashboardManagement} />
        <Route path="/dashboardmanagement-add" component={DashboardAdd} />

        <Route path="/formentries" component={FormEntriesList} />
        <Route path="/formentries-view/:ID" component={FormEntriesView} />

        <Route path="/coupons" component={Couponlist} />
	    	<Route path="/coupon-add" component={CouponAdd} />
        <Route path="/coupon-edit/:ID" component={CouponEdit} />

        <Route path='/plugincontent' component={Cmscontent} />

        <Route path="/category" component={CategoryList} />
        <Route path="/category-edit/:ID" component={CategoryEdit} />
        <Route path="/category-view/:ID" component={CategoryView} />
        <Route path="/category-add" component={CategoryAdd} />

        <Route path="/appointment-history" component={AppointmentHistoryList} />
        <Route path="/appointment-history-view/:ID" component={AppointmentHistoryView} />

        <Route path="/FamilyRelation" component={FamilyRelationList} />
        <Route path="/FamilyRelation-edit/:ID" component={FamilyRelationEdit} />
        <Route path="/FamilyRelation-view/:ID" component={FamilyRelationView} />
        <Route path="/FamilyRelation-add" component={FamilyRelationAdd} />
        
        <Route path="/Prescription" component={PrescriptionList} />
        <Route path="/Prescription-edit/:ID" component={PrescriptionEdit} />
        <Route path="/Prescription-view/:ID" component={PrescriptionView} />
        <Route path="/Prescription-add" component={PrescriptionAdd} />
        
        <Route path="/ProductCategory" component={ProductCategoryList} />
        <Route path="/ProductCategory-edit/:ID" component={ProductCategoryEdit} />
        <Route path="/ProductCategory-view/:ID" component={ProductCategoryView} />
        <Route path="/ProductCategory-add" component={ProductCategoryAdd} />

        <Route path="/Outofstock" component={OutofstockList} />
        <Route path="/Outofstock-view/:ID" component={OutofstockView} />

        
        <Route path="/Product" component={ProductList} />
        <Route path="/Product-edit/:ID" component={ProductEdit} />
        <Route path="/Product-view/:ID" component={ProductView} />
        <Route path="/Product-add" component={ProductAdd} />
        
        <Route path="/product-booking-history" component={ProductBookingHistoryList} />
        <Route path="/product-booking-history-view/:ID" component={ProductBookingHistoryView} />

        <Route path="/company-clone/:ID" component={CompanyClone} />

        <Route path="/doctor-visite-management" component={DoctorVisiteBookingList} />
        <Route path="/doctor-allocate/:ID" component={DoctorAssign} />

        <Route path="/insurance" component={InsuranceList} />
        <Route path="/insurance-add" component={InsuranceAdd} />
        <Route path="/insurance-edit/:ID" component={InsuranceEdit} />
        <Route path="/insurance-view/:ID" component={InsuranceView} />

        <Route path="/insurancecategory" component={InsuranceCategoryList} />
        <Route path="/insurancecategory-add" component={InsuranceCategoryAdd} />
        <Route path="/insurancecategory-edit/:ID" component={InsuranceCategoryEdit} />

        <Route path="/subscribers" component={SubscribersList} />
        <Route path="/subscribers-view/:ID" component={SubscribersView} />   

        <Route path="/faqcategory" component={FaqCategoryList} />
        <Route path="/faqcategory-add" component={FaqCategoryAdd} />
        <Route path="/faqcategory-edit/:ID" component={FaqCategoryEdit} />  
        <Route path="/faqcategory-view/:ID" component={FaqCategoryView} />  

        <Route path="/faq" component={FaqList} />
        <Route path="/faq-add" component={FaqAdd} />
        <Route path="/faq-edit/:ID" component={FaqEdit} />
        <Route path="/faq-view/:ID" component={FaqView} />
        <Route path="/faq-import" component={FaqImport} />

        <Route path="/aifeed" component={AiFeedList} />    

        <Route path="/location" component={LocationList} />
        <Route path="/location-edit/:ID" component={LocationEdit} />
        <Route path="/location-view/:ID" component={LocationView} />
        <Route path="/location-add" component={LocationAdd} />

        <Route path="/Guide" component={GuideList} />
        <Route path="/Guide-edit/:ID" component={GuideEdit} />
        <Route path="/Guide-view/:ID" component={GuideView} />
        <Route path="/Guide-add" component={GuideAdd} />

        <Route path="/aifeed-import" component={AIImport} /> 

        <Route path="/InviteDiscount" component={InviteDiscountList} />
        <Route path="/InviteDiscount-add" component={InviteDiscountAdd} />
        <Route path="/InviteDiscount-edit/:ID" component={InviteDiscountEdit} />  

        <Route path="/referaluserlist" component={ReferalUserList} />
        <Route path="/referaluser-view/:ID" component={ReferalUserView} />

        <Route path="/providerinventory" component={List} />
        <Route path="/inventory-add" component={Add} />
        <Route path="/inventory-edit/:ID" component={Edit} />

        <Route path="/requestinventory" component={RefillList} />
        <Route path="/requestinventory-add" component={RefillAdd} />
        <Route path="/requestinventory-edit/:ID" component={RefillEdit} />

        <Route path="/rolemanagement" component={RoleList} />
        <Route path="/rolemanagement-add" component={RoleAdd} />
        <Route path="/rolemanagement-edit/:ID" component={RoleEdit} />

        <Route path="/userrolemanagement" component={UserRoleList} />
        <Route path="/userrolemanagement-add" component={UserRoleAdd} />
        <Route path="/userrolemanagement-edit/:ID" component={UserRoleEdit} />
        <Route path="/userrolemanagement-view/:ID" component={UserRoleView} />

        <Route path="/Advertisement" component={AdvertisementList} />
        <Route path="/Advertisement-add" component={AdvertisementAdd} />
        <Route path="/Advertisement-edit/:ID" component={AdvertisementEdit} />
        {/* <Route path="/userrolemanagement-view/:ID" component={UserRoleView} /> */}

        <Route path="/AdvertisementCategory" component={AdvertisementCategoryList} />
        <Route path="/AdvertisementCategory-add" component={AdvertisementCategoryAdd} />
        <Route path="/AdvertisementCategory-edit/:ID" component={AdvertisementCategoryEdit} />
       

        <Route path="/providerrevenue" component={ProviderRevenue} />
        <Route path="/providerrevenue-view/:ID" component={ProviderRevenueView} /> 

      </Switch>
      </QueryProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);