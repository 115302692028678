import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';

import "react-datepicker/dist/react-datepicker.css";

import CKEditor from 'ckeditor4-react';
	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			cat_name:'',
			short_description:'',
			description:'',
			image:'',
			loader_image_preview:'',
			loader_image_name: '',
			fileExtension: '',
			loader_image: 'file',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			emailtemplatelist:[],
			template_name:'',
			link:''
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onEditorChangedescription = this.onEditorChangedescription.bind( this );
		this.onEditorChangeshort_description = this.onEditorChangeshort_description.bind( this );
    }
	  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
		if(name == 'send_as_email'){
			this.setState({send_as_email_checked: !this.state.send_as_email_checked})
		}


  }

  onEditorChangedescription( evt ) {
	// console.log(evt.editor.getData(), 'CKE')
	var callfunc = this;
	setTimeout(function () {
	callfunc.setState( {
		description: evt.editor.getData()
	} );
	}, 1000);
}
onEditorChangeshort_description( evt ) {
	// console.log(evt.editor.getData(), 'CKE')
	var callfunc = this;
	setTimeout(function () {
	callfunc.setState( {
		short_description: evt.editor.getData()
	} );
	}, 1000);
}
  onChangeHandler=event=>{
	
	let reader = new FileReader();
	const file = event.target.files[0];
	const extension = file.name.split('.').pop();



	reader.onloadend = () => {
	  this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file,
		fileExtension:extension,		
	  });
	};
	reader.readAsDataURL(file);	

  }
  componentDidMount() {
	
	var admin_id = localStorage.getItem('admin_id');
	axios.get(apiUrl+'Guide/getemailTemplates?admin_id='+admin_id)
	
	.then(res => {
		if(res.data.status == 'success'){
		
			this.setState({
				emailtemplatelist: [{value: '', label: 'Select Template'}].concat(res.data.emailtemplatelist)
			});
		
		}else{
			
		}
	});
}
  handleSubmit = () => {
	if(this.validateForm()){
		const formdata = this.state;
		var qs = require('qs');
		var status = '';

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		if(formdata.selectedvalue === ''){
		status =formdata.selectedOption.value;
		}else{
		status = formdata.selectedvalue;
		}
		var send_as_email = 0;
		if(this.state.send_as_email_checked == true){
			var send_as_email = 1;
		}

		var img_name = this.state.image;
		var postObject ={
			admin_id: localStorage.getItem('admin_id'),
			name : formdata.cat_name,
			description: formdata.description,
			short_description: formdata.short_description,
			send_as_email:send_as_email,
			emailtemplate_id:formdata.template_name,
			image : formdata.image,
			link : formdata.link,
			status: status
		}
		let formData = new FormData();
		for(let k in postObject) {
			formData.append(k, postObject[k]);
		}
		axios.post(apiUrl+"Guide/add",formData,config).then(res => {
			if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html(this.props.history.push('/Guide'));
					}
					.bind(this),
					3000
					);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
	}

	validateForm() {
		const {cat_name,short_description,image,send_as_email_checked,template_name} = this.state;
		const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

		let errors = {};
		  let formIsValid = true;
	
		
		  if(!cat_name){
			formIsValid = false;
			$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorcat_name').html('');
		}
	
		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}
			
		// if (!image) {
		// 	alert()
		// 	if (!allowedTypes.includes(image?.type)) {
		// 		formIsValid = false;
		// 		$('.errorfile').html('<span class="errorspan">Invalid type</span>');
		// 	  }else{
		// 		$('.errorfile').html('');
		// 	  }
		// 	}

		if (!image) {
			formIsValid = false;
			$('.errorfile').html('<span class="errorspan">Please fill the feild</span>');
				}else{
					$('.errorfile').html('');
				}
				if (image) {		
		if (!image.name.match(/\.(jpg|jpeg|png|pdf|doc|docx|txt)$/)) {
			formIsValid = false;
		 		$('.errorfile').html('<span class="errorspan">Please select valid type</span>');
		 	  
		  }else{
			$('.errorfile').html('');
		  }
		}
		  if(this.state.send_as_email_checked == true){
			var send_as_email = 1;
			if (!template_name) {
			formIsValid = false;
			$('.errortemplate_name').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errortemplate_name').html('');
			}
		}else {
			$('.errortemplate_name').html('');
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
		
  

	}
	handleClickSelecetemailTemplate = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');
		this.setState({
				template_name: keys,		
		});
	}

  render() {

	
	const {selectedOption,image_preview,fileExtension} = this.state;
	let loaderimg = '';
	let preview = '';

	
	
	var current = this;	

	if(this.state.fileExtension === ('jpg' || 'jpeg' || 'png')){

		preview = <img className="img_class" src={this.state.image_preview} alt="" />;
	}else if(this.state.fileExtension ===('docs' ||'pdf' ||'txt')){
	
		preview =  <a href = {this.state.image_preview} target = "_blank">Download {fileExtension}</a>;
	}else if(this.state.fileExtension ===""){
		 preview = '';
	}

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="Guide" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Guide</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Guide  Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="cat_name" onChange={this.handleInputChange} className="form-control" value={this.state.cat_name}/>
					<div className="errorcat_name"></div>
				</div>
				
				<div className="form-group">					
					<label>Description:</label>
						{/* <textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} /> */}
						<CKEditor
							data={this.state.description}
							//data={templatecontent}
							onChange={this.onEditorChangedescription}
							type="classic"
						/>
					<div className="errordescription"></div>
				</div>
				<div className="form-group">
							<input onChange={this.handleInputChange} type="checkbox"  name="send_as_email" checked={this.state.send_as_email_checked}/>
							<label> Send as Email</label>
					</div>
					{this.state.send_as_email_checked == true ? <div className="form-group emailtemp_div">
					<label>Email Templates<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={current.handleClickSelecetemailTemplate}  >                    
                    {this.state.emailtemplatelist.map((emailtemplatelist) => <option key={emailtemplatelist.value} data-key={emailtemplatelist.value} value={emailtemplatelist.value} >{emailtemplatelist.label}</option>)}
                    </select>
					<div className="errortemplate_name"></div>
				 </div>:null}
				<div className="form-group">
					<label>Guide Upload (jpeg,jpg,png,pdf,doc,docx,txt):</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorfile"></div>  
				</div> 
			</div>
                  
			<div className="form-right">
			

				<div className="form-group">					
					<label>Youtube Link :</label>
						<input type="link" name="link" onChange={this.handleInputChange} className="form-control" value={this.state.link}  />
					<div className="errorlink"></div>
				</div>
			<div className="form-group">					
					<label>Short Description<span class="required" style={{ color: "red" }} > * </span> :</label>
{/* 						<textarea type="text" name="short_description" onChange={this.handleInputChange} className="form-control" value={this.state.short_description}  /> */}

						<CKEditor
							data={this.state.short_description}
							//data={templatecontent}
							onChange={this.onEditorChangeshort_description}
							type="classic"
						/>
					<div className="errorshort_description"></div>
				</div>

			

				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange}
                            />
	           </div> 
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));