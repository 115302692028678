import axios from 'axios';
import { apiUrl} from'../Config/Config';

const insurance_plan = () => {
    let planLists = [];
    axios.post(apiUrl+"insurance/plantypes").then(res => {
        if(res.data.status == "success"){
            let i=0;
            let planlist = res.data.planlist;
            
            for(i; i<planlist.length; i++){
                planLists[i] = { value: planlist[i]['id'], label: planlist[i]['title'] };
            }
            
        }       
    });
    return planLists;
}

const categories = () => {
    var qs = require('qs');
    var postobject = {};

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

const categories1 = () => {
    var qs = require('qs');

    var postobject = {
        type: 'all'
    };

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

export const lang = {

    common: {
        title:'Leaa Doctor',
        status_option:[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
        ],
        
        gender_option: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' }
        ],
        insurance_plan_option: insurance_plan(),
        categories_option: categories(),
        categories_option1: categories1(),
		/* image_size_option: [
            { value: '250x250', label: '250x250' },
			{ value: '350x350', label: '350x350' },
            { value: '520x520', label: '520X520' }
        ],*/
        image_size_option: [
            { value: '1031x400', label: '1031x400 (W X H)' },
            { value: '250x180', label: '250x180 (W X H)' },
			{ value: '200x150', label: '200x150 (W X H)' },
            { value: '180x120', label: '180x120 (W X H)' }
        ],
        coming_source :[
            { value: '0', label: 'Select' },
            { value: 'friends', label: 'Friends' },
            { value: 'facebook', label: 'Facebook' },
            { value: 'instagram', label: 'Instagram' },
            { value: 'messaging apps', label: 'Messaging Apps' },
            { value: 'website', label: 'Website' },
            { value: 'flyers', label: 'Flyers' },
            { value: 'sms', label: 'Sms' },
            { value: 'newspaper', label: 'Newspapers' },
            { value: 'others', label: 'Others' }
        ],
         field_options :[
            { value: 'home', label: 'Home' },
            { value: 'membership', label: 'Membership' },
            { value: 'memberlist', label: 'Family Members' },
            { value: 'pending', label: 'Pending' },
            { value: 'accepted', label: 'Accepted' },
            { value: 'completed', label: 'Completed' },
            { value: 'viewmap', label: 'View Map' },
            { value: 'order', label: 'Order' },
            { value: 'onlineprovider', label: 'Online Providers' },
            { value: 'schedule', label: 'My Schedule' },
            { value: 'monthly', label: 'Monthly Earning' },
            { value: 'yearly', label: 'Yearly Earning' },
            { value: 'invited', label: 'Invited' },
            { value: 'invitedfriends', label: 'Invited friends' },
            { value: 'users', label: 'User list' },
            { value: 'provider', label: 'Provider list' },
            { value: 'provideravail', label: 'Provider Availability' },
            { value: 'reviews', label: 'Reviews' },
            { value: 'apptrevenue', label: 'Appointment Monthly Revenue' },
            { value: 'apptyrrevenue', label: 'Appointment Yearly Revenue' },

        ],
        ads_field_options :[
            // { value: 'home', label: 'Home' },
            // { value: 'pending', label: 'Pending' },
            // { value: 'accepted', label: 'Accepted' },
            // { value: 'completed', label: 'Completed' },
            // { value: 'order', label: 'Order' },
            
            { value: 'maintab', label: 'Home' },
            { value: 'primarycare', label: 'Primary Care' },
            { value: 'membership', label: 'Membership' },
            { value: 'store', label: 'Store' },
            { value: 'myBag', label: 'My Bag' },
        ],
        company_field_options :[      
            { value: 'recommendedproduct', label: 'Recommended product' },    
            { value: 'provider', label: 'Provider list' },
            { value: 'provideravail', label: 'Provider Availability' },
            { value: 'pending', label: 'Pending' },
            { value: 'accepted', label: 'Accepted' },
            { value: 'completed', label: 'Completed' },           
            { value: 'monthly', label: 'Monthly Earning' },
            { value: 'yearly', label: 'Yearly Earning' },
            { value: 'users', label: 'User list' },
            { value: 'order', label: 'Order' },
            { value: 'reviews', label: 'Reviews' }
        ],
        user_field_options :[
            { value: 'book', label: 'Book a home visit' },
            { value: 'invited', label: 'Invited' },
            { value: 'invitedfriends', label: 'Invited friends' },
            { value: 'memberlist', label: 'Family Members' },
            { value: 'order', label: 'Order' },
            { value: 'viewmap', label: 'View Map' },          
            { value: 'pending', label: 'Pending' },
            { value: 'accepted', label: 'Accepted' },
            { value: 'completed', label: 'Completed' },
        ],
        doctor_field_options :[
            { value: 'recommendedproduct', label: 'Recommended Product' },
            { value: 'yearly', label: 'Yearly Earning' },
            { value: 'monthly', label: 'Monthly Earning' },
            { value: 'apptrevenue', label: 'Appointment Revenue' },
            { value: 'pending', label: 'Pending' },
            { value: 'accepted', label: 'Accepted' },
            { value: 'completed', label: 'Completed' },
            { value: 'schedule', label: 'My Schedule' },
            { value: 'onlineprovider', label: 'Online Providers' },
            { value: 'recommendproduct', label: 'Recommend Product' },
            { value: 'account_management', label: 'Account Management' },
            { value: 'revenue', label: 'Revenue Management' },
            // { value: 'mybagorder', label: 'Mybag Orders' },
            // { value: 'mybag', label: 'Mybag list' },
            { value: 'refillrequest', label: 'Refill request' },
        ],
        
        discount_option:[
            { value: 'percentage', label: 'Percentage' },
            { value: 'standard', label: 'Standard' },
        ],
        login_type:[
            { value: 'User', label: 'User' },
            { value: 'Provider', label: 'Provider' },
            { value: 'Company', label: 'Company' },
        ],
        module_option:[
            { value: 'module1', label: 'Module1 (Provider Listing)' },
            { value: 'module2', label: 'Module2 (Product Listing)' },
            { value: 'module4', label: 'Module4 (Ebag Products)' },
            { value: 'book', label: 'Book a Home Visit' },
        ],
        productType_option:[
            { value: '1', label: 'Normal Product' },
            { value: '2', label: 'Shopping Product' },
            { value: '3', label: 'Regular Product' },
        ],
        product_show:[
            { value: 'user', label: 'User' },
            { value: 'doctor', label: 'Doctor' },
        ],
        advertisement_linktype:[
            { value: 'internal', label: 'Internal' },
            { value: 'external', label: 'External' },
            { value: 'offer', label: 'Offers' },
        ],
    },
};


