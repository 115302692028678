import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import {GET_ADDUSER }  from '../../actions'; 
const initialState = {
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			status:'',
			user_password: '',
            user_confirm_password: '',
			selectedDomain:'',
			domainvalue:'',
			domainlist:[],
            new_pass_type: 'password',
            confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			disablepasssword:true
		};
		
class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	    this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
       this.handleChange = this.handleChange.bind(this);

    }
	 handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name == 'password'){
      	this.setState({disablepasssword: false})
      }
    }
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	componentDidMount() {
		
		document.title = PageTitle('Add user');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}

		var admin_id = localStorage.getItem('admin_id');
			axios.get(apiUrl+'user/getdomainlist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){				
					this.setState({
						domainlist: [{value: '', label: 'Select Your Domain'}].concat(res.data.domainlist)
					})				
				}else{
				}
			});
		

    }
	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    } 

	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					domain_id : formPayload.domain_id,
					userid       : formPayload.userid,
					username     : formPayload.username,
					firstname    : formPayload.firstname,
					lastname     : formPayload.lastname,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					password     : formPayload.user_password,
					confirm_password:formPayload.user_confirm_password, 
					status       :  status,
				};
				this.props.getAddUser(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {username,firstname, lastname, email, phoneno, user_password, user_confirm_password,domain_id} = this.state;
		let errors = 0;
		if (!username) {
			errors++;
			$('.errorusername').html('<span class="errorspan">Please fill the field</span>');
		}else if(username){
			$('.errorusername').html('');
		}

		/*if (!domain_id) {
			errors++;
			$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		}else if(domain_id){
			$('.errorassign_domain').html('');
		}*/

		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			$('.errorfirstname').html('');
		}

		if (!lastname) {
			errors++;
			$('.errorlastname').html('<span class="errorspan">Please fill the field</span>');
		}else if(lastname){
			$('.errorlastname').html('');
		}


		if (!email) {
	    	errors++;			
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			var reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		    // var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
		    if (reg.test(email) == false) 
	        {
				errors++;				
	            $('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
	        }else{
	        	$('.erroremail').html('');
	        }
		}

	


		// if (!user_phoneno) {
	    // 	errors++;
		// 	formIsValid = false;
		// 	$('.errorphoneno').html('<span class="errorspan">Please check mobile number</span>');
		// }else if(user_phoneno){	
		// 	// var pattern = /^[1-9][0-9]{9}$/;
		// 	 var pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
		// 	//var pattern = new RegExp(/^[0-9\b]+$/);
		// 	 if (pattern.test(user_phoneno)) {
        //    		$('.errorphoneno').html('');
        //      }else{
        //      	errors++;
		// 	      formIsValid = false;
        //      	$('.errorphoneno').html('<span class="errorspan">Please enter valid mobile number</span>');
        //      }				
		// }

		if (!phoneno) {
			errors++;
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
			    if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid phone number</span>');
				   }
		}		   

		if (!user_password) {
			errors++;
			$('.errorpassword').html('<span class="errorspan">Please fill the field</span>');
		}else if(user_password){
		 if (user_password.match(/[a-z]/g) && user_password.match( 
                    /[A-Z]/g) && user_password.match( 
                    /[0-9]/g) && user_password.match( 
                    /[^a-zA-Z\d]/g) && user_password.length >= 8){
		 				$('.errorpassword').html('');
		 }else{
		 	errors++;
			$('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}

		if (!user_confirm_password) {
			errors++;
			$('.errorchangepaswd').html('<span class="errorspan">Please fill the confirm password</span>');
		}else if(user_confirm_password){
		 if (user_confirm_password.match(/[a-z]/g) && user_confirm_password.match( 
                    /[A-Z]/g) && user_confirm_password.match( 
                    /[0-9]/g) && user_confirm_password.match( 
                    /[^a-zA-Z\d]/g) && user_confirm_password.length >= 8){
		 				$('.errorchangepaswd').html('');
		 }else{
		 	errors++;
			$('.errorchangepaswd').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}
		if(user_password !== user_confirm_password){
          	errors++;
            $('.errorchangepaswd').html('<span class="errorspan">Confirm Password does not match</span>');
        }

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

		handleClickSelecet = (event) => {
			const selectedIndex = event.target.options.selectedIndex;
			var key = event.target.options[selectedIndex].getAttribute('data-key');

			this.setState({
					domain_id: key,
					
			})
	

		}

    componentWillReceiveProps(Props){
    		if(Props.useradd !== this.props.useradd){
    		if(Object.keys(Props.useradd).length > 0){
				this.setState({Loading:false});
				if(Props.useradd[0].status === "success"){

				 $('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
				scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/user'));
						}
						.bind(this),
						3000
						);
					this.setState({username:'',firstname:'',lastname:'',email:'',phoneno:'',user_password:'',user_confirm_password:''})
					}else if(Props.useradd[0].status === "erroremail"){

					 $('.erroremail').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.erroremail').html('');
							}
							.bind(this),
							3000
							);
					}else if(Props.useradd[0].status === "errorphone"){

					 $('.errorphoneno').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.errorphoneno').html('');
							}
							.bind(this),
							3000
							);
					}else if(Props.useradd[0].status === "errorusername"){

					 $('.errorusername').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.errorusername').html('');
							}
							.bind(this),
							10000
							);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
						scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    }
	  
	
  render() {
  	let preview = '';
  	const {selectedOption,selectedDomain,domainlist} = this.state;
	  var current = this;


    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="user" />  

	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div className="title">
				<h4>Add User</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>First Name<span class="required" style={{ color: "red" }} > * </span>:</label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				<div className="form-group">
					<label>Last Name<span class="required" style={{ color: "red" }} > * </span>:</label>
				    <input type="text" name="lastname" onChange={this.handleInputChange} className="form-control" value={this.state.lastname} />
					<div className="errorlastname"></div>
				</div>
				
				<div className="form-group eye-pad">
                    <label>New Password<span class="required" style={{ color: "red" }} > * </span>:</label>
                    <input type={this.state.new_pass_type}  name="user_password" placeholder="Enter new password" className="form-control" value={this.state.user_password} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                 </div>
                  <div className="errorpassword"></div>
			   	  <div className="form-group eye-pad">
                    <label>Confirm Password<span class="required" style={{ color: "red" }} > * </span>:</label>
                    <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
					
				  </div>
				  <div className="errorchangepaswd"></div>
                  
				</div>
			<div className="form-right">
			    <div className="form-group">
					<label>User Name<span class="required" style={{ color: "red" }} > * </span>:</label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} autoComplete="off"/>
					<div className="errorusername"></div>
				</div>
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} > * </span>:</label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} autoComplete="off" />
					<div className="erroremail"></div>
				</div>

				<div className="form-group">
					<label>Phone number<span class="required" style={{ color: "red" }} > * </span>:</label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				

			  {/*	<div className="form-group">					
					<label>Assign Domain : </label>
					<select  onChange={current.handleClickSelecet}  value={domainlist.domain_name}>                    
        {this.state.domainlist.map((domainlist) => <option key={domainlist.value} data-key={domainlist.value} value={domainlist.value} >{domainlist.label}</option>)}
            </select>
						
					<div className="errorassign_domain"></div>
	</div> */}
                 <div className="default-font">
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
				</div>
			</div>

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	useradd     : state.useradd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddUser: (formPayload) =>{
       dispatch({ type: GET_ADDUSER, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));