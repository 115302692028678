import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import axios from 'axios';
import $ from 'jquery';
import {
  Table
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl, apiUrl,doctorURL} from '../Config/Config';
import QueryContext from '../QueryContext';
class List extends Component {
  static contextType = QueryContext;
  constructor(props)
  {
     super(props); 
      this.state = {
          doctorlist:'',
          editable: false,
          activePage: 1,
          totalRecord: '',
          search_all:'',
			    certificate_verified_checked:false
      };

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {

    var qs = require('qs');
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: localStorage.getItem('admin_id')  
    };

    axios.post(apiUrl+"doctor/list",qs.stringify(postobject)).then(res => {
      if(res.data.status === 'success'){ 
          this.setState({  doctorlist: res.data.doctorlist, totalRecord: res.data.records_count}, function() {
            this.doctorlist();
          });

      }
    }); 
   }


  handlePageChange(pageNumber) {
   // console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
    
    axios.post(apiUrl+"doctor/list",qs.stringify(postobject)).then(res => {
      if(res.data.status === 'success'){ 
          this.setState({  doctorlist: res.data.doctorlist, totalRecord: res.data.records_count}, function() {
            this.doctorlist();
          });

      }
    }); 

  }

  handleInputChange(event) {
  const {name, value} = event.target;      
  this.setState({
    [name]: value
  });
  if(name == 'certificate_verified'){
    this.setState({certificate_verified_checked:!this.state.certificate_verified_checked});
    alert(!this.state.certificate_verified_checked);
  }
  
}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : 1,
          search_all : formPayload.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        //console.log(postObject);
        axios.post(apiUrl+"doctor/list",qs.stringify(postObject)).then(res => {
          if(res.data.status === 'success'){ 
              this.setState({  doctorlist: res.data.doctorlist, totalRecord: res.data.records_count}, function() {
                this.doctorlist();
              });    
          }else{
            this.setState({  doctorlist:'', totalRecord: ''}, function() {
              this.doctorlist();
            });    
          }        
        }); 
  } 

   componentWillReceiveProps(Props){
    
	
	if(Props.changestatus !== this.props.changestatus){
      if (Props.changestatus[0]["status"] === "success") {
            var qs = require('qs');
            var postobject = {
           activePage: this.state.activePage,
            search_all: '',
            admin_id : localStorage.getItem("admin_id")
            };
            this.props.getUserList(qs.stringify(postobject));
      }
    }
	
	
	if(Props.exportuser !== this.props.exportuser){
		
      if (Props.exportuser[0]["status"] === "success") {
		  let url =baseUrl+"/"+Props.exportuser[0]["message"];
          window.open(url,
  '_blank' // <- This is what makes it open in a new window.
);
      }
    }
   }

   deleteConfirm=(confirmstatus,id)=>{
    if(confirmstatus == 'yes'){
			$('.confirm-action-delete').removeClass('show');
      
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id
        };
        var curr = this;
        axios.post(apiUrl+"doctor/delete",qs.stringify(postObject)).then(res => {
          if(res.data.status === 'success'){
     
            $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

              setTimeout(
              function() {
                $('.success_message').html('');
                window.location.reload(); 
              }
              .bind(this),
              3000
              );
          }
        }); 
      }else{
        $('.confirm-action-delete').removeClass('show');	
      }
   }

activateuser(id, status){
	let stat = ''
		if(status == 'Inactive'){
			stat = "A";
		}else{
			stat = "I";
		}
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id,
		      status: stat
        };
      axios.post(apiUrl+"doctor/change_status",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

          setTimeout(
          function() {
            $('.success_message').html('');
          }
          .bind(this),
          3000
          );
          var qs = require('qs');
          var postobject = {
            activePage: 1,
            search_all: '',
            admin_id: localStorage.getItem('admin_id')  
          };
          axios.post(apiUrl+"doctor/list",qs.stringify(postobject)).then(res => {
            if(res.data.status === 'success'){
                this.setState({  doctorlist: res.data.doctorlist, totalRecord: res.data.records_count}, function() {
                  this.doctorlist();
                });
      
            }
          }); 
  
        }
      }); 
}
verifieddoctor(id, Verification){
	let stat = ''
		if(Verification == 'checked'){
			stat = "No";
		}else{
			stat = "Yes";
		}
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id,
		      status: stat
        };
      axios.post(apiUrl+"doctor/cerificate_verify",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

          setTimeout(
          function() {
            $('.success_message').html('');
          }
          .bind(this),
          3000
          );
          var qs = require('qs');
          var postobject = {
            activePage: 1,
            search_all: '',
            admin_id: localStorage.getItem('admin_id')  
          };
          axios.post(apiUrl+"doctor/list",qs.stringify(postobject)).then(res => {
            if(res.data.status === 'success'){
                this.setState({  doctorlist: res.data.doctorlist, totalRecord: res.data.records_count}, function() {
                  this.doctorlist();
                });
      
            }
          }); 
  
        }
      }); 
}

exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
      this.props.getExportUser(qs.stringify(postObject));
}
 doctorlist(ehrpanel) {
  var doctorlist = this.state.doctorlist;
  if (doctorlist != "undefined" && doctorlist != null) {
    if (Object.keys(doctorlist).length > 0) {
      const doctorlistDetails = doctorlist.map(
        (data, Index) => {
          let sno = Index+1;

          if(data.status == 'A'){
            var status = 'Active';
          }else{
              status = 'Inactive';
          }

          

          var mobileno = ((data.phone == null)||(data.phone == '') || (data.phone == 0)) ?  data.phone = 'N/A' : data.phone;
        
          if((data.certificate_verified) =='Yes'){
            var Verification = 'checked';
					}else{
            Verification = '';

					}

          return (
            <tr key={data.id}>
            <td>{sno}</td>
              <td>
              {data.name}
              </td>
              <td>
              {data.username}
              </td>
              <td>{data.org_password}</td>
              <td>{data.domain_name}</td>
              <td>{data.email}</td>
              <td>{mobileno}</td>
              <td>{data.cat_name}</td>
              <td>{status}</td> 
              <td>{ data.certificate != '' && data.certificate != null ? <div>
                      <a className="icon" href={doctorURL + "/" + data.certificate} id='img_1' target='_blank'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 512 512"><path d="M398.6 169.2c-.9-2.2-2-4.3-3.5-6.1l-83.8-91.7c-1.9-2.1-4.2-3.6-6.7-4.9-2.9-1.5-6.1-2.1-9.5-2.1H135.2c-12.4 0-22.7 10.6-22.7 23.9v335.2c0 13.4 10.3 24.9 22.7 24.9h243.1c12.4 0 22.2-11.5 22.2-24.9V179.4c0-3.6-.5-7.1-1.9-10.2zm-238.1 9.4c0-1.5 1.8-2.1 3.4-2.1h70.8c1.6 0 2.8.6 2.8 2.1v10.8c0 1.4-1.1 3.1-2.8 3.1h-70.8c-1.6 0-3.4-1.7-3.4-3.1v-10.8zm0 128c0-1.5 1.8-2.1 3.4-2.1h122.2c1.6 0 2.4.6 2.4 2.1v10.8c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8zm160 74.8c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8c0-1.5 1.8-2.1 3.4-2.1h154.2c1.6 0 2.4.6 2.4 2.1v10.8zm32-128c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8c0-1.5 1.8-2.1 3.4-2.1h186.2c1.6 0 2.4.6 2.4 2.1v10.8zm-46.9-75.9c-5.6 0-11.1-5.2-11.1-11.3v-66l71.2 77.3h-60.1z"/></svg>
                      </a>
                      <div>
                        <input  onChange={this.verifieddoctor.bind(this, data.id, Verification)} type="checkbox"  name="certificate_verified"checked={Verification} />
							        </div>
                    </div>
                    : 'N/A' }
              </td>
              <td className="actiongroup">
              {(ehrpanel)? <Link to={{ pathname: '/doctor-view/'+data.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>:
                <>
                 <Link to={{ pathname: '/doctor-edit/'+data.id}} className="" title="Duplicate">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                 {(status == 'Active'?<Link onClick={this.activateuser.bind(this, data.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activateuser.bind(this, data.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}   
                 
                 <Link onClick={this.deletedata.bind(this,data.id)} className="" title="Delete"><i className="ft-trash-2" aria-hidden="true"></i></Link>    </>}
              </td>
            </tr>
          );
        }
      );
      return doctorlistDetails;
    } else {
    return (
      <tr>
        <td colSpan="10" className="v-align-nr text-center">
          No Result
        </td>
      </tr>
    );
  }
   }   
}


deletedata(id){
  this.setState({rowid : id})
  $('.confirm-action-delete-message').html('Are you sure delete the Doctor?');	
  $('.confirm-action-delete').addClass('show');	
 }
  
  render() {
    const { ehrpanel } = this.context;
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="doctors" />  

  
  <div className="content"> 
    <div className="content-wrapper">
      <div class="content-wrapper-before"></div>
      <div className="car-listing-wrap leaa-admin-head card">
      <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
            <h3>Provider</h3>
          </div>
          <div className="search-list">
                <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                </form>
          </div> 
          {(ehrpanel)?<div className="add_button"></div>: <div className="add_button">  
      		 <a className="" href='/doctor-add'>Add</a>
			     {/* <a className="" href='/user-import'>Import <i className="import-icon icon" aria-hidden="true"></i> &nbsp;|&nbsp;</a>
           <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="ft-upload" aria-hidden="true"></i></Link>*/}
          </div>}
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
			          <th>Username</th>
                <th>Password</th>
                <th>Domain</th>
                <th>Email</th>
				        <th>Contact</th>
                <th>Specialist</th>
                <th>Status</th>
                <th>Certificate Verification</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.doctorlist(ehrpanel)}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
  <div className="confirm-action-delete">
								<p className="confirm-action-delete-message"></p>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.rowid)} className="btn">Yes</a>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
						  </div>

    </div>
    );
  }
}
export default withRouter(List);