import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,categoryURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';



class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const id = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            id: id,
			productlist:[],
			doctorlist:[],
			selecteddoctor:'',
			selecteddocvalue:'',
			product_name:'',
			productid:'',
			provider_qty:''
		};
		// this.props.getCategoryDetailList(id);
		var qs = require('qs');
		var invid = this.state.id;

		axios.get(apiUrl+"providerinventory/listdetail?id="+invid).then(res => {
		
			this.setState({product_name:res.data.detail.name,productid:res.data.detail.id,provider_qty:res.data.detail.quantity});

			
			if(res.data.selecteddoctors!==null && res.data.selecteddoctors.length > 0){
					this.setState({ selecteddoctor : res.data.selecteddoctors, selecteddocvalue: res.data.selecteddocid})
				
			}
		}); 

		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'providerinventory/getproviderproduct?admin_id='+admin_id)
		
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					productlist: [{value: '', label: 'Select Products'}].concat(res.data.productlist),
					doctorlist: res.data.doctorlist
				});
			
			}else{
				
			}
		});
		
		this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
	
    }
	
	
	handleClickSelecetCategory = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');
		var id = event.target.options[selectedIndex].getAttribute('data-id');
	
		this.setState({
				product_name: keys,	productid: id
		});
	
	}
	handleClickSelecet = (selOption) => {
	
		let doctors = [];
		let selValue = [];
		if(selOption!== null){
			selOption.map(item => {
				selValue.push(item.value);
				doctors.push({ label: item.label, value: item.value });
			});
			this.setState({selecteddoctor:doctors});
			this.setState({  selecteddocvalue : selValue});
		}else{
			this.setState({selecteddoctor:''});
			this.setState({  selecteddocvalue : ''});
		}
		
		
	
	 };

	componentDidMount() {
		document.title = PageTitle('Provider Inventory Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
			
				const formdata = this.state;
				var qs = require('qs');
				var status = '';
		
				const config = {
					headers: {
						'content-type': 'multipart/form-data'
					}
				}
				var postObject ={
					admin_id: localStorage.getItem('admin_id'),
					product:formdata.product_name,
					productid: formdata.productid,
					selecteddocvalue: formdata.selecteddocvalue,
					provider_qty:formdata.provider_qty,
				}
			       
				let formData = new FormData();
				for(let k in postObject) {
					formData.append(k, postObject[k]);
				}
				axios.post(apiUrl+"providerinventory/edit",formData,config).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/providerinventory'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {provider_qty,selecteddoctor,product_name} = this.state;
		let errors = {};
      	let formIsValid = true;
		  if (!provider_qty) {
			formIsValid = false;
			$('.errorprovider_qty').html('<span class="errorspan">Please enter price</span>');
		}else if(provider_qty){
			$('.errorprovider_qty').html('');
		}
	
		if (!selecteddoctor) {
			formIsValid = false;
			$('.errordoctors').html('<span class="errorspan">Please select doctors</span>');
		}else if(selecteddoctor){
			$('.errordoctors').html('');
		}
	
		if (!product_name) {
			formIsValid = false;
			$('.errorproducts').html('<span class="errorspan">Please select products</span>');
		}else if(product_name){
			$('.errorproducts').html('');
		}
	

		this.setState({
			errors: errors
		});
		console.log(formIsValid,'errors')
		return formIsValid;
    }

    componentWillReceiveProps(Props){


   	if(Props.categorydetails !== this.props.categorydetails){
   		
    		if(Object.keys(Props.categorydetails).length > 0){
				this.setState({Loading:false});

					if(Props.categorydetails[0].status === "success"){

						const formdata = Props.categorydetails[0].categorylist;
						
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}

						if( parseInt (formdata.form_builder_id) !==0 ){

							this.setState({forms: 1,products:0});
							this.setState({form_select: 'forms'});
						}else{
							this.setState({forms: 0,products:1});
							this.setState({form_select: 'products'});
						}
		

						this.setState({image_preview:formdata.image});
						this.setState({product_name:formdata.product_name});
						this.setState({product_id: formdata.product_id});						
						this.setState({short_product_id: formdata.short_product_id});
                        this.setState({id: formdata.id});


                        
					}
    		}
    	}

    	if(Props.categoryedit !== this.props.categoryedit){
    		if(Object.keys(Props.categoryedit).length > 0){
				if(Props.categoryedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.categoryedit[0].categorylist;

				if(formpayload.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});	
					}	
					this.setState({image_preview:formpayload.image});
					this.setState({product_name:formpayload.product_name});
					this.setState({product_id: formpayload.product_id});						
					this.setState({short_product_id: formpayload.short_product_id});
					this.setState({id: formpayload.id});
			
                    	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
               			scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/category'));
						}
						.bind(this),
						3000
						);
						
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
					scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}		
    }
  render() {

	
	const {productlist,doctorlist, productid} = this.state;

  	var current = this;
    return (
	
		<div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="Providerinventory" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Products</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">					
						<label>Product Name :</label>
							<input type="text" name="product_name" onChange={this.handleInputChange} className="form-control" value={this.state.product_name}  readOnly />
						<div className="errorprovider_qty"></div>
					</div> 
					<div className="form-group">					
						<label>Quantity<span class="required" style={{ color: "red" }} > * </span> :</label>
							<input type="text" name="provider_qty" onChange={this.handleInputChange} className="form-control" value={this.state.provider_qty} readOnly />
						<div className="errorprovider_qty"></div>
					</div> 
			</div>
                  
			<div className="form-right">
			<div className="form-group">
			<label>Assign Doctor<span class="required" style={{ color: "red" }} > * </span> : </label>
				 

				 <Select 
					 value={this.state.selecteddoctor?this.state.selecteddoctor:""}
					 options={this.state.doctorlist} 
					 onChange={this.handleClickSelecet.bind(this)}
					 isSearchable={true}
					 isMulti
					 placeholder={'Select Doctor'}
					 />
				 <div className="errordoctors"></div>
			  </div>

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Edit));