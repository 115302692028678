import {SET_ADDFORM, SET_ACTIVATEFORM, SET_FORMDETAIL, SET_EDITFORM, SET_FORMLIST,SET_FORMENTRIESLIST, SET_FORMENTRYDETAIL } from '../actions';

export const formadd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDFORM:
      return [...action.value];  
    default: return state;
  }
}

export const formactive = (state = [], action) => {
  switch (action.type) {
	case SET_ACTIVATEFORM:
      return [...action.value];  
    default: return state;
  }
}


export const formdetail = (state = [], action) => {
  switch (action.type) {
	case SET_FORMDETAIL:
      return [...action.value];  
    default: return state;
  }
}

export const formedit = (state = [], action) => {
  switch (action.type) {
	case SET_EDITFORM:
      return [...action.value];  
    default: return state;
  }
}


export const formlist = (state = [], action) => {
  switch (action.type) {
	case SET_FORMLIST:
      return [...action.value];  
    default: return state;
  }
}

export const entrieslist = (state = [], action)=> {
  switch(action.type){
    case SET_FORMENTRIESLIST:
      return [...action.value];
      default: return state;
  }
}

export const entriesview =(state = [], action) => {
  switch(action.type){
    case SET_FORMENTRYDETAIL:
     return[...action.value];
     default: return state;
  }
}