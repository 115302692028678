import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWUSERLIST } from '../../actions';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const userid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      userid: userid,
      userlistview:'',
      familtlistview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :userid
    };
    this.props.getViewUserList(qs.stringify(postObject));  
  }

   
   componentDidMount() {
     document.title = PageTitle('User View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.userlistview!==this.state.userlistview){
        if(Props.userlistview[0].status == "success"){
          this.setState({userlistview: Props.userlistview[0].userlistview[0]}) 
          this.setState({familtlistview: Props.userlistview[0].familylist}) 
          this.familylist();
        }
      }
   }
   familylist() {
    var familtlist = this.state.familtlistview;
    if (familtlist != "undefined" && familtlist != null) {
      // if ( == "success"){
        if (Object.keys(familtlist).length > 0) {
          const familylistDetails = familtlist.map(
            (familtlist, Index) => {
              let sno = Index+1;
              return (
                <tr key={familtlist.id}>
				         <td>{sno}</td>
                   <td>
                  {familtlist.relationship}
                  </td>
                  <td>
                  {familtlist.name}
                  </td>
                  <td>
                  {familtlist.gender}
                  </td>
                  <td>
                  {familtlist.email}
                  </td>
                  <td>
                  {familtlist.mobile}
                  </td>
                  <td>
                  {familtlist.dob}
                  </td>
                  <td>
                  {familtlist.age}
                  </td>
                 
                </tr>
              );
            }
          );
          return familylistDetails;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }
    
    }
  }

  render() {
     if((this.state.userlistview.subscribed_plan_name!='')&&(this.state.userlistview.subscribed_plan_name!=null)){
        if(this.state.userlistview.subscribed_plan_name == "Free"){

         var plan_paid_amount = "$"+0;
        }else{
           var plan_paid_amount = "$"+this.state.userlistview.plan_paid_amount;
        }
    }else{
     var plan_paid_amount = "N/A";
    }

    if((this.state.userlistview.subscribed_plan_name!='')&&(this.state.userlistview.subscribed_plan_name!=null)){
        var subscribed_plan_name = this.state.userlistview.subscribed_plan_name;
    }else{
     var subscribed_plan_name = "N/A";
    }


    if((this.state.userlistview.transactionId!='')&&(this.state.userlistview.transactionId!=null)){
        var transactionId = this.state.userlistview.transactionId;
    }else{
     var transactionId = "N/A";
    }

    var mobileno = ((this.state.userlistview.mobileno === null)||((this.state.userlistview.mobileno === ''))) ?  this.state.userlistview.mobileno = 'N/A' : this.state.userlistview.mobileno;

    var lastname = ((this.state.userlistview.lastname === null)||(this.state.userlistview.lastname === "")) ?  this.state.userlistview.lastname = 'N/A' : this.state.userlistview.lastname;


    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="ongoingride" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>User Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>User Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.username}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>First Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.firstname}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Last Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{lastname}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.email}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Mobile number<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{mobileno}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>User Type<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.user_type}</p>
                          </div>
                        </div>

                       <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Plan type<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{subscribed_plan_name}</p>
                          </div>
                          
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Paid Plan amount<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{plan_paid_amount}</p>
                          </div>
                        </div>

                         <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Transaction ID<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{transactionId}</p>
                          </div>
                          </div>
                         {(this.state.userlistview.admin_type!== null && this.state.userlistview.adminname!== undefined) && <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status Changed by<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.adminname+' ('+this.state.userlistview.admin_type+')'}</p>
                          </div>
                          </div>}


                    </div>
                </div>
                  <div className="title">
                    <h3>Family Details</h3>
                  </div>
                  <div className="form-row">
                  <div className="car-listing-row">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Relationship</th>
                            <th>Name</th>
                            <th>Gender</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>DOB</th>
                            <th>Age</th>
                          </tr>
                        </thead>
                        <tbody>{this.familylist()}</tbody>
                      </Table>
                      {/* <div className="text-center">
                        <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalRecord}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        />
                      </div> */}
                    </div>
                </div>
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
                  
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    userlistview: state.userlistview
    
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewUserList: (userid) =>{
       dispatch({ type: GET_VIEWUSERLIST,userid });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));