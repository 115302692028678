import React, { Component } from 'react';
import { useParams, useNavigate, Link,withRouter } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { apiUrl, mediaUrl} from'../Config/Config';
import { lang } from '../Helpers/lang';
import Select from 'react-select';
import axios from 'axios';
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

//import { GET_CARLIST,GET_DELETECAR,GET_PRIORITYUPDATE } from '../../actions';


// const withRouter = WrappedComponent => props => {
// 	const params = useParams();
// 	const  navigate = useNavigate();
// 	// etc... other react-router-dom v6 hooks
  
// 	return (
// 	  <WrappedComponent
// 		{...props}
// 		params={params}
// 		navigate ={navigate}
// 		// etc...
// 	  />
// 	);
//   };  
class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      adslist:'',
	  activePage: 1,
	  totalRecord: '',
	  search_all:'',
	  selectedStatus:'',
	  selectid:'',
    adid:'',
    
    selectedFilOption:'',
    selectedFilvalue:'',
    };

   

    //this.props.getCarList(qs.stringify(postobject));

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
      // document.title = PageTitle('Car List');
      // if(localStorage.getItem('admin_id') === null){
      // this.props.history.push('/');
      // }
	  this.listloadedData()
   }
   
    listloadedData(postobject='') {
  var qs = require('qs');
   if(postobject!=''){
    var postobject = postobject
   }else{
    var postobject = {
      activePage: this.state.activePage,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
   }
  
 axios.post(apiUrl+"advertisement/list",qs.stringify(postobject)).then(res => {
   
   if(res.data.status === 'success'){	   
       this.setState({adslist: res.data, totalRecord: res.data.records_count}, function() {
         this.adslist();
       });
   }else{
    this.setState({  adslist: '', totalRecord: 0}, function() {
      this.adslist();
    });
   }
 }); 
}

    handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});

      var qs = require('qs');

      var admin_id = localStorage.getItem("admin_id");

      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
	  this.listloadedData(postobject);
     // this.props.getCarList(qs.stringify(postobject))
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    var postObject = {             
      activePage   : this.state.activePage,
      search_all : value,
      admin_id: localStorage.getItem('admin_id')           
    };
    this.listloadedData(postObject)
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
         
           var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
            activePage   : 1,
            search_all : this.state.search_all,
            filterVal : this.state.selectedFilvalue,
            admin_id: admin_id         
          };
		  this.listloadedData(postObject);
          //console.log(postObject);
        //  this.props.getCarList(qs.stringify(postObject));
        
    } 

  
   
   handleChange = (name,carid,event) => {
	  this.setState({priorityid: name,priorityvalue: event.target.value})
	   var qs = require('qs');
			var postObject = {
			  value :event.target.value,
			  id  :carid
		};
	 // this.props.getPriorityUpdate(qs.stringify(postObject));

	}

	deletead(id){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id
        };
      //this.props.getDeleteCar(qs.stringify(postObject));
      this.setState({adid : id})
      $('.confirm-action-delete-message').html('Are you sure! you want to delete the advertisement?');	
      $('.confirm-action-delete').addClass('show');	
	}

  adslist() {
	 // console.log(this.state.adslist,'adslist')
    var adslist = this.state.adslist;
    if (adslist != "undefined" && adslist != null) {
      if (adslist.status == "success") {
        if (Object.keys(adslist).length > 0) {
          const adslistDetails = adslist.adslist.map(
            (adslist, Index) => {
              let sno = Index+1;
              if(adslist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
						  
			
              return (
                <tr key={adslist.id}>
                <td>{sno}</td>
                  <td>{adslist.name}</td>
                  <td>{adslist.cate_name}</td>
                  <td>{adslist.link_type}</td>
                  <td>{adslist.images&&<img src={mediaUrl+'/advertisement/'+adslist.images}  alt="No Image" width="80px" />}</td>				  
                  {/* <td>{adslist.description}</td> */}
                  <td>{status}</td>
                  <td className="actiongroup">
                    <Link to={{ pathname: '/advertisement-edit/'+adslist.id}} className="" title="Duplicate">  <i class="ft-edit-3" aria-hidden="true"></i></Link>
                     <Link onClick={this.deletead.bind(this,adslist.id)} className="" title="Duplicate">  <i class="ft-trash-2" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return adslistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="7" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  deleteConfirm =(confirmstatus,id)=>{
    if(confirmstatus == 'yes'){
			$('.confirm-action-delete').removeClass('show');
      var qs = require('qs');       
      var postObject = { 
        column: 'id',         
        value: id,   
        activePage:this.state.activePage,  
        admin_id:localStorage.getItem("admin_id")
      };
      axios.post(apiUrl+"advertisement/delete",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
          setTimeout(
          function() {
          $('.success_message').html('');
          }
          .bind(this),
          3000
          );

          this.listloadedData()
       
        }
      });  
    }else{
      $('.confirm-action-delete').removeClass('show');	
    }
  }
  handleFilChange = (selOption) => {       	
    this.setState({selectedFilOption:selOption})
    this.setState({  selectedFilvalue : selOption.value},function(){
      this.handleFormSubmit()
    });
  };
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="advertisement" />  
      
      <div className="content"> 
        <div className="content-wrapper">
          
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">

              <div className="listing-header">

                
                <div className="title">
                  <h3>{this.state.totalRecord > 0?this.state.totalRecord:''} Advertisement{this.state.totalRecord>1 ? 's' : ''}</h3>
                </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                   <div className="input_search">
                        <div className="search-inner search_input">
                          <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                          <button className="" type="submit">Submit</button>
                          <div className="erroremail"></div>
                        </div>
                        {/* <div className="search-inner search_select">
                        <Select name="category" options={lang.common.status_filter_option} 
                          //onChange={this.handleInputChange}
                          onChange={this.handleFilChange.bind(this)}
                        />
                      </div>   */}
                  </div>
                 </form>
                </div>
                <div className="add_button">  
                    <a className="primary-btn" href='/advertisement-add'>Add</a> 
                </div>  
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Category</th>
                      <th>Link</th>
                      <th>Image</th>
					            {/* <th>Description</th>                       */}
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.adslist()}</tbody>
                </Table>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
      <div className="confirm-action-delete">
          <p className="confirm-action-delete-message"></p>
          
          
          <a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.adid)} className="btn">Yes</a>
          <a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
          <span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
        </div>
    </div>
    );
  }
}

export default withRouter(List);
/*
const mapStateTopProps = (state) => {
  return {
    carlist: state.carlist,
    deletecar: state.deletecar,
	  priorityupdate: state.priorityupdate
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCarList: (formVal) =>{
       dispatch({ type: GET_CARLIST, formVal});
    },
    getDeleteCar: (formPayload) =>{
      dispatch({ type: GET_DELETECAR, formPayload });
    },	
	getPriorityUpdate: (formPayload) =>{
      dispatch({ type: GET_PRIORITYUPDATE, formPayload });
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));*/