import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Table
} from "react-bootstrap"; 

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, categoryURL ,tapURL} from'../Config/Config';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { lang } from '../Helpers/lang';
// const withRouter = WrappedComponent => props => {
//   const params = useParams();
//   const  navigate = useNavigate();
//   // etc... other react-router-dom v6 hooks
  
//   return (
//     <WrappedComponent
//     {...props}
//     params={params}
//     navigate ={navigate}
//     // etc...
//     />
//   );
//   };
class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      tablist:'',
      // domainlist:[],
      activePage:1,
      sno_index:0,
      tablistload:'',
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      this.tablistload()
   }
tablistload(postobject=''){
  var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      if(postobject!=''){
        var postobject=postobject;
      }else{
        var postobject = {
          activePage: 1,
          search_all: '',
          admin_id: admin_id,
          loggedas: loggedas
          };  
      }
     

      axios.post(apiUrl+"cmsmanagement/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  cmslist: res.data.cmslist, totalRecord: res.data.records_count}, function() {
            this.setState({cmslistload:this.cmslist()})
              
            });
  
        }else{
          this.setState({  cmslist: res.data.cmslist, totalRecord: res.data.records_count}, function() {
            
            this.setState({cmslistload:this.cmslist()})
          });
        }
      }); 
}
   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});
     var sno_index = pageNumber - 1;
     this.setState({sno_index: sno_index});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.gettablist(qs.stringify(postobject))
    this.tablistload(postobject)

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  this.tablistload(postObject)
 

} 

   

  deletetab(){
   
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :this.state.selectid,
         
        };

      axios.post(apiUrl+"cmsmanagement/delete",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          var postobject1 = {
            activePage: this.state.activePage,
            search_all: '',
            admin_id : localStorage.getItem("admin_id")
          };
          this.tablistload(postobject1)

         
          $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
          setTimeout(
            function() {
           
              $('.success_message').html('');
               
            }
            .bind(this),
            3000
            );
            
      }
      }); 
  }

activatecategory(){
  let stat = ''
  if(this.state.selectedStatus ==='Inactive'){
    stat = "I";
  }else{
    stat = "A";
  } 
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :this.state.selectid,
          status: stat
        };

      axios.post(apiUrl+"cmsmanagement/change_status",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          var postobject1 = {
            activePage: this.state.activePage,
            search_all: '',
            admin_id : localStorage.getItem("admin_id")
          };
          this.tablistload(postobject1)

         
          $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
          setTimeout(
            function() {
           
              $('.success_message').html('');
               
            }
            .bind(this),
            3000
            );
            
      }
      }); 
}

cmslist() {
  let catImg = '';
  let preview = '';
    var cmslist = this.state.cmslist;
    var sno_index = this.state.sno_index;
    if (cmslist != "undefined" && cmslist != null) {
      // console.log(categorylis);
    
      // if (tablist.status == "success") {

        if (Object.keys(cmslist).length > 0) {
          const helperlistDetails = cmslist.map(
            (cmslist, Index) => {
              if(sno_index > 0){ Index = (sno_index * 10) + Index; } 
              let sno = Index+1;              

              if(cmslist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={cmslist.id}>
      					<td>{sno}</td>
      					<td>{cmslist.name}</td>
                <td>{cmslist.slug}</td>
                {/* <td><img className="img_class" src={`${tapURL}/${cmslist.image}`} alt="" /></td> */}
                {/* <td>{lang.Capitalize(status)}</td> */}
                <td>{status}</td>
                <td className="actiongroup" >                    
                   <Link to={{ pathname: '/offers-view/'+cmslist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>  
                                    
                     <Link to={{ pathname: '/offers-edit/'+cmslist.id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link> 

                     {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, cmslist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, cmslist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}       
                      <Link onClick={this.confirmPopup.bind(this,cmslist.id,'delete')} className="" title="Delete"><i className="ft-trash-2" aria-hidden="true"></i></Link> 
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      // } else {
      //   return (
      //     <tr>
      //       <td colspan="11" className="v-align-nr">
      //         No Result
      //       </td>
      //     </tr>
      //   );
      // }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  confirmPopup = (id, status) => {
    var status = status;
    if(status == 'Inactive'){
      status = "Active";
    }else if(status == 'Active'){
      status = "Inactive";
    }else{
      status="Delete";
    }
    this.setState({selectid: id, selectedStatus: status})	
    $('.confirm-action').addClass('show');		
  }
  
  Confirmstatus = (confirmstatus) => {
    if(confirmstatus == 'yes'){
      if(this.state.selectedStatus=="Delete"){
        this.deletetab()
      }else{
        this.activatecategory();
      }
     
      $('.confirm-action').removeClass('show');
    }else{
      $('.confirm-action').removeClass('show');	
    }
      
  }


  render() {

    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the Offer?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="cms" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Offers</h3>
                </div>
                <div className="search-list">
                <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                      <div className="search-inner">
                        <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                      <button className="" type="submit">Submit</button>
                      <div className="erroremail"></div>
                    </div>
                  </form>
            </div> 

                    {/* <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> */}
                <div className="add_button">  
                    <a className="" href='/offers-add'>Add</a>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Offer Title</th>
                  <th>Slug</th>
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.state.cmslistload}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default (withRouter(List));