import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { useState } from "react";
import { Button } from 'react-bootstrap';
	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			product_name:'',
			price:'',
			image:'',
			loader_image_preview:'',
			loader_image_name: '',
			loader_image: 'file',
			description:'',
		    cat_name:'',
			description:'',
			doctor_visited:false,
			no_of_visite:'',
			categorynamelist:[],
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			selectedProductOption:{ value: '2', label: 'Shopping Product'},
			quantity:'',
			selectedproductvalue:2,
			ebagproduct:false,
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleProductChange = this.handleProductChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
    }
	  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };
   handleProductChange = selectedProductOption => {
	this.setState({selectedProductOption})
	this.setState({  selectedproductvalue : selectedProductOption.value});
	var productType = selectedProductOption.value;

	if(productType==1){
		$('.pricediv').hide();
	}else{
		$('.pricediv').show();
	}
	
};



   handleInputChange(event) {
	console.log(event)
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
		if(name === 'doctor_visited'){
			if(this.state.doctor_visited==false){
				$('.doctor_visite_count').show();
			}
			else{
				$('.doctor_visite_count').hide();
			}
			this.setState({doctor_visited: !this.state.doctor_visited});			
	    }	
	   	if(name ==='no_of_visite'){
			if(value<1){
				this.setState({no_of_visite:''});
			}
	   	}
		// if(name == 'productshowuser'){
		// 	this.setState({producttoshowuser: !this.state.producttoshowuser})
		// }
		// if(name == 'productshowdoctor'){
		// 	this.setState({producttoshowdoctor: !this.state.producttoshowdoctor})
		// }
		if(name == 'ebagproduct'){
			this.setState({ebagproduct: !this.state.ebagproduct})
		}
  }
  handleClickSelecetCategory = (event) => {
	const selectedIndex = event.target.options.selectedIndex;
	var keys = event.target.options[selectedIndex].getAttribute('data-key');
	this.setState({
			cat_name: keys,		
	});
}

componentDidMount() {
	
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'product/getcategorynamelist?admin_id='+admin_id)
		
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					categorynamelist: [{value: '', label: 'Select Category'}].concat(res.data.categorynamelist)
				});
			
			}else{
				
			}
		});
	}
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		});
		};
		reader.readAsDataURL(file);
	}


  handleSubmit = () => {
	if(this.validateForm()){
		
	const formdata = this.state;
	var qs = require('qs');
	var status = '';
	var doctor_visited = '';

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}

	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

	if(formdata.doctor_visited === true){
		doctor_visited ='Yes';
		}else{
			doctor_visited = 'No';
		}

	if(formdata.selectedproductvalue === '')
	{
		var product_type =formdata.selectedProductOption.value;
	}else{
		var product_type = formdata.selectedproductvalue;
	}
	/* if(formdata.producttoshowuser ==  true && formdata.producttoshowdoctor ==  true)
	{
		var productshow = 'both';
	}else if(formdata.producttoshowuser ==  true && formdata.producttoshowdoctor ==  false){
		var productshow = 'user';
	}else{
		var productshow = 'doctor';
	} */

	if(formdata.ebagproduct ==  true)
		{
			var ebagproduct = 'yes';
		}else{
			var ebagproduct = 'no';
		}
	var img_name = this.state.image;
	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		product_name : formdata.product_name,
		price: formdata.price,
		quantity: formdata.quantity,
		category_id:formdata.cat_name,
		description: formdata.description,
		image : formdata.image,
		doctor_visited: doctor_visited,
		no_of_visite:formdata.no_of_visite,
		status: status,
		product_type: product_type,
		ebagproduct: ebagproduct
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"product/add",formData,config).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/product'));
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
			}
		});
		
	}
  }
	validateForm() {
		const {product_name,cat_name,
		price,
		quantity,
		prescription_detail,
		doctor_visited,no_of_visite,selectedproductvalue, ebagproduct} = this.state;

		let errors = {};
      	let formIsValid = true;

		  if(this.state.ebagproduct ==  false){
			if(!cat_name){
				formIsValid = false;
				$('.errorcategory_name').html('<span class="errorspan">Please fill the field</span>');
			}else{
				
				$('.errorcategory_name').html('');
			}
		}

		if (!product_name) {
			formIsValid = false;
			$('.errorproduct_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(product_name){
			$('.errorproduct_name').html('');
		}
	
		if(doctor_visited==true)
		{
			if (!no_of_visite) {
				formIsValid = false;
				$('.errorno_of_visite').html('<span class="errorspan">Please fill the field</span>');
			}else if(no_of_visite){
				$('.errorno_of_visite').html('');
			}
		}
		if(!selectedproductvalue){
        	formIsValid = false;
        	$('.errorproductype').html('<span class="errorspan">Please select product type</span>');
		}else{
			
			$('.errorproductype').html('');
		}
		if(this.state.ebagproduct == false){

			if((selectedproductvalue==2 || selectedproductvalue==3 )){
				if (!price) {
					formIsValid = false;
					$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
				}else if(price){
					$('.errorprice').html('');
				}
				
			}
		}
		
		if (!quantity) {
			formIsValid = false;
			$('.errorquantity').html('<span class="errorspan">Please fill the field</span>');
		}else if(quantity){
			$('.errorquantity').html('');
		}
     
		this.setState({
			errors: errors
		});
		return formIsValid;
    }


  render() {

	let preview = '';
	const {selectedOption,categorynamelist,selectedProductOption,selectedproductvalue} = this.state;

	var current = this;	

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="Product" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Product</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Product Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="product_name" onChange={this.handleInputChange} className="form-control" value={this.state.medicine_name}/>
					<div className="errorproduct_name"></div>
				</div>
				<div className="form-group">					
					<label>Ebag product:</label>
						 <input onChange={this.handleInputChange} type="checkbox" name="ebagproduct" checked={this.state.ebagproduct}/>
	           	</div> 
				 <div className="form-group">
					<label>Quantity<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="quantity" onChange={this.handleInputChange} className="form-control" value={this.state.quantity}/>
					<div className="errorquantity"></div>
				</div>
				 {this.state.ebagproduct == false &&
				<div className="form-group">
					<label>Category<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={current.handleClickSelecetCategory}  value={categorynamelist.cat_name}>                    
                    {this.state.categorynamelist.map((categorynamelist) => <option key={categorynamelist.value} data-key={categorynamelist.value} value={categorynamelist.value} >{categorynamelist.label}</option>)}
                    </select>
					<div className="errorcategory_name"></div>
				 </div>
  				}
				<div className="form-group">					
					<label>Product Description:</label>
						<textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.prescription_detail} />
					<div className="errordescription"></div>
				</div>
				<div className="form-group">
						<input onChange={this.handleInputChange} type="checkbox"  name="doctor_visited"  checked={this.state.doctor_visited}/>
						<span>Doctor Visit</span>
				</div> 	
				<div className="form-group doctor_visite_count">
					<label>No of Visit<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="no_of_visite" onChange={this.handleInputChange} className="form-control" value={this.state.no_of_visite}/>
					<div className="errorno_of_visite"></div>
				</div>
			</div>
			<div className="form-right">
				<div className="form-group">					
					<label>Product Type<span class="required" style={{ color: "red" }} > * </span> :</label>
						<Select 
							name="product_type"
							value={selectedProductOption?selectedProductOption:{ value: '2', label: 'Shopping Product'}}
							options={lang.common.productType_option} 
							isSearchable = {false}
							onChange={this.handleProductChange}
						/>
	           	</div> 
				<div className="form-group pricediv">
					<label>Price ($): </label>
				    <input type="number" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price}/>
					<div className="errorprice"></div>
				</div>
			
				{/* <div className="form-group">					
					<label>Product to Show<span class="required" style={{ color: "red" }} > * </span> :</label>

						 <input onChange={this.handleInputChange} type="checkbox" name="productshowuser" checked={this.state.producttoshowuser}/>
		                <span>User</span>
		                 <input onChange={this.handleInputChange} type="checkbox"  name="productshowdoctor" checked={this.state.producttoshowdoctor}/>
		                <span>Doctor</span>
	           	</div>  */}
				   <div className="form-group">
					<label>Icon:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorloaderimage"></div>  
				</div> 
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						<Select 
							name="status"
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
							options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange}
						/>
	           	</div> 
			
				
			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));