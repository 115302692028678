 /*local */ 
    // export const apiUrl      = "http://localhost/leeadoctor/api/";
    // export const baseUrl      = "http://localhost/leeadoctor";
    // export const mediaUrl      = "http://localhost/leeadoctor/media/";
    // export const carImageUrl      = "http://localhost/leeadoctor/media/cars";
    // export const sampleFileUrl      = "http://localhost/leeadoctor/media/sample_file/";
    // export const licenseURL       = "http://localhost/leeadoctor/media/driverlicense";
    // export const insuranceURL       = "http://localhost/leeadoctor/media/driverinsurance";
    // export const profileURL       = "http://localhost/leeadoctor/media/driverprofile";
    // export const drivercarURL       = "http://localhost/leeadoctor/media/drivercarimage";
    // export const cmscontentURL       = "http://localhost/leeadoctor/media/cmscontent";
    // export const categoryURL       = "http://localhost/leeadoctor/media/category";
    // export const specialistURL       = "http://localhost/leeadoctor/media/specialists";
    // export const productURL       = "http://localhost/leeadoctor/media/product";
    // export const formentriesURL       = "http://localhost/leeadoctor/media/formentries";
    // export const invoiceURL       = "http://localhost/leeadoctor/media/order_invoice";
    // export const doctorURL       = "http://localhost/leeadoctor/media/doctor"; 
    // export const guideURL       = "http://localhost/leeadoctor/media/guide"; 

/*Live */
// export const apiUrl      = "http://localhost/leeadoctor/api/";
export const apiUrl      = "https://d-admin.uxt.design/leeadoctor/api/";
export const baseUrl      = "https://d-admin.uxt.design/leeadoctor";
export const mediaUrl      = "https://d-admin.uxt.design/leeadoctor/media/";
export const carImageUrl      = "https://d-admin.uxt.design/leeadoctor/media/cars";
export const sampleFileUrl      = "https://d-admin.uxt.design/leeadoctor/media/sample_file/";
export const licenseURL       = "https://d-admin.uxt.design/leeadoctor/media/driverlicense";
export const insuranceURL       = "https://d-admin.uxt.design/leeadoctor/media/driverinsurance";
export const profileURL       = "https://d-admin.uxt.design/leeadoctor/media/driverprofile";
export const drivercarURL       = "https://d-admin.uxt.design/leeadoctor/media/drivercarimage";
export const cmscontentURL       = "https://d-admin.uxt.design/leeadoctor/media/cmscontent";
export const categoryURL       = "https://d-admin.uxt.design/leeadoctor/media/category";
export const specialistURL       = "https://d-admin.uxt.design/leeadoctor/media/specialists";
export const productURL       = "https://d-admin.uxt.design/leeadoctor/media/product";
export const formentriesURL       = "https://d-admin.uxt.design/leeadoctor/media/formentries";
export const invoiceURL       = "https://d-admin.uxt.design/leeadoctor/media/order_invoice";
export const doctorURL       = "https://d-admin.uxt.design/leeadoctor/media/doctor"; 
export const guideURL       = "https://d-admin.uxt.design/leeadoctor/media/guide"; 
export const cmsURL       = "https://d-admin.uxt.design/leeadoctor/media/cmsmanagement"; 
