import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const font_id = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      font_id: font_id,
      fontlistview:'',
    };

   

    axios.get(apiUrl+"Font/listdetail?id="+font_id).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ fontlistview: res.data.fontlist});
      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Font View');
   }
  render() {
  if(this.state.fontlistview!==undefined){
    var fontlistview = this.state.fontlistview;
  }

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Font" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Font Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Font Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{fontlistview.tf_name}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Exact Family Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{fontlistview.tf_font_family}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Font Import url<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{fontlistview.tf_import_url}</p>
                        </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b> Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(fontlistview.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                     
                    

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));