import React, { Component } from 'react';
import {
  Table
} from "react-bootstrap";
import $ from 'jquery';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";

import { Link, withRouter } from "react-router-dom";
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

/* import { GET_FONTLIST, GET_DELETEFONT } from '../../actions'; */

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      categorylist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:'',
      selectid:'',
      selectedStatus:"",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }
  componentDidMount() {
      document.title = PageTitle('Fonts');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
      this.listloadData()
   }
listloadData(postobject=''){
  var admin_id = localStorage.getItem("admin_id");
  var qs = require('qs');
  if(postobject!=''){
    var postobject=postobject;
  }else{
    var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id
    };
  }
  
axios.post(apiUrl+"AdvertisementCategory/list",qs.stringify(postobject)).then(res => {
 
 if(res.data.status === 'success'){
  this.setState({ totalRecord : res.data.records_count, categorylist: res.data.categorylist}, function() {
    this.categorylist();
  });
 }else{
  this.setState({ totalRecord : 0, categorylist: ''}, function() {
    this.categorylist();
  });
 }
}); 
}
   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
      this.listloadData(postobject)
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    var admin_id = localStorage.getItem("admin_id");
    var postobject = {
      activePage: 1,
      search_all: value,
      admin_id: admin_id
    };
    this.listloadData(postobject)
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          this.listloadData(postObject)
        
    }
    confirmPopup = (id,status) => {
      var status = status;
      if(status == 'Inactive'){
        status = "Active";
      }else{
        status = "Inactive";
      }
     
      this.setState({selectid: id, selectedStatus: status})	
      $('.confirm-action').addClass('show');		
    }
    activatecate(){

      let stat = ''
        if(this.state.selectedStatus ==='Inactive'){
          stat = "I";
        }else{
          stat = "A";
        }  
        var qs = require('qs');
            var postObject = {
              admin_id : localStorage.getItem("admin_id"),
              id  :this.state.selectid,
              status: stat
            };
            axios.post(apiUrl+"AdvertisementCategory/change_status",qs.stringify(postObject)).then(res => {      
              if(res.data.status === 'success'){
                  this.listloadData()
                  $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
                  setTimeout(
                    function() {
                   
                      $('.success_message').html('');
                       
                    }
                    .bind(this),
                    3000
                    );
                    
              }
            }); 
    }
    
    Confirmstatus = (confirmstatus) => {
      if(confirmstatus == 'yes'){
        this.activatecate();
        $('.confirm-action').removeClass('show');
      }else{
        $('.confirm-action').removeClass('show');	
      }
    }
    deleteConfirm =(confirmstatus,cateid)=>{
      if(confirmstatus == 'yes'){
        $('.confirm-action-delete').removeClass('show');
        var admin_id = localStorage.getItem("admin_id");
        var qs = require('qs');       
        var postObject = { 
          id  :this.state.selectid,
          admin_id  : admin_id
        };
        axios.post(apiUrl+"AdvertisementCategory/delete",qs.stringify(postObject)).then(res => {
          if(res.data.status === 'success'){
              $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
              setTimeout(
              function() {
              $('.success_message').html('');
              }
              .bind(this),
              3000
              );  
              this.listloadData()
            }
        }); 
      }else{
        $('.confirm-action-delete').removeClass('show');	
      }
    }
 
deletecompany(id){
  this.setState({selectid : id})
  $('.confirm-action-delete-message').html('Are you sure! you want to delete the category?');	
  $('.confirm-action-delete').addClass('show');	
 }
categorylist() {
    var categorylist = this.state.categorylist;
    if (categorylist != "undefined" && categorylist != null) {
        if (Object.keys(categorylist).length > 0) {
          const fontlistDetails = categorylist.map(
            (categorylist, Index) => {
              let sno = Index+1;
              var status;
              if(categorylist.status == 'A'){
                 status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={categorylist.id}>
                <td>{sno}</td>
                  <td>
                  {categorylist.cate_name}
                  </td>
                  <td>{status}</td>
                  <td>
                   <Link to={{ pathname: '/advertisementcategory-edit/'+categorylist.id}} className="" title="edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                 
                   {/* {(status == 'Active')?<Link onClick={this.confirmPopup.bind(this, categorylist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, categorylist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>}  */}

                    {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, categorylist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, categorylist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}    

                    <a class="" title="Status" href=""><i class="ft-unlock" aria-hidden="true"></i></a>


                     <Link onClick={this.deletecompany.bind(this, categorylist.id)} className="" title="delete">  <i className="ft-trash-2" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return fontlistDetails ;
        }  else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
  <div className="confirm-action">
			<p>Are you sure! you want to delete the advertisement category?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="advertisementcategory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
              <div class="content-wrapper-before"></div>
            <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                <h3>{this.state.totalRecord > 0?this.state.totalRecord:''} Advertisement Category{this.state.totalRecord>1 ? '' : ''}</h3> 
                </div>
                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
              <div className="add_button">  
                    <a className=""href='/advertisementcategory-add'>Add</a> 
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}
              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Title</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.categorylist()}</tbody>
                </Table>
                 <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
              </div>
            </div> 
        </div>  
      </div>
      <div className="confirm-action-delete">
								<p className="confirm-action-delete-message"></p>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.userid)} className="btn">Yes</a>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
						 </div>
    </div>
    );
  }
}

export default withRouter(List);