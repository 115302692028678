import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';

class Edit extends Component {
	constructor(props)
	{
		super(props);	
		var RolesId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			rolesId:RolesId,
			fname:'',
			lname:'',
			email:'',
			contact:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
            selectedRole:{},
			selectedRolevalue:'',
            selectedMenu:{},
			selectedMenuvalue:'',
            modulelist:'',
            rolelist:'',
		};
		var qs = require('qs');
		var rolesId = this.state.rolesId;
		axios.get(apiUrl+"company/getroledetail?id="+rolesId).then(res => {
			this.setState({
				contact:res.data.list.contact,
				email:res.data.list.email,
				fname:res.data.list.first_name,
				lname:res.data.list.last_name,
				selectedRole:{label:res.data.list.role_name,value:res.data.list.roles},
				selectedRolevalue:res.data.list.roles,
				selectedMenuvalue:res.data.checkmodules,
				selectedMenu:res.data.module,
			});
			if(res.data.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
		}); 
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
		activePage: 1,
		search_all: '',
		admin_id: admin_id,
      };  
      axios.post(apiUrl+"company/getuserrole",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){         
            this.setState({  rolelist: res.data.rolename, modulelist: res.data.modulelist});  
        }
      }); 
   	}

	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
    handleChangeRole = selectedRole => {
        this.setState({selectedRole})
        this.setState({  selectedRolevalue : selectedRole.value});
    };

    handleChangeMenu = (selOption) => {
	let modules = [];
	let selValue = [];
	if(selOption!== null){
		selOption.map(item => {
			selValue.push(item.value);
			modules.push({ label: item.label, value: item.value });
		});
		this.setState({selectedMenu:modules});
		this.setState({  selectedMenuvalue : selValue});
	}else{
		this.setState({selectedMenu:''});
		this.setState({  selectedMenuvalue : ''});
	}
	
	

 };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

  
    handleSubmit = () => {
	if(this.validateForm()){
		const formdata = this.state;
		var qs = require('qs');
		var status = '';
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
		if(formdata.selectedvalue === ''){
		status =formdata.selectedOption.value;
		}else{
		status = formdata.selectedvalue;
		}
        
		var postObject ={
            editid:this.state.rolesId,
			admin_id: localStorage.getItem('admin_id'),
			firstname : formdata.fname,
			lastname : formdata.lname,
			email : formdata.email,
			contact : formdata.contact,
			status: status,
			role_id: formdata.selectedRole.value,
            role: formdata.selectedRole.label,
			selected_module: formdata.selectedMenuvalue,
		}	
		axios.post(apiUrl+"company/addroles",qs.stringify(postObject)).then(res => {
			if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html(this.props.history.push('/userrolemanagement'));
					}
					.bind(this),
					3000
					);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
	}

	validateForm() {
		const {fname,email,selectedRolevalue,selectedMenuvalue} = this.state;
	
		let errors = {};
		  let formIsValid = true;
	
		
		if(!fname){
			formIsValid = false;
			$('.error_fname').html('<span class="errorspan">Please fill the field</span>');
		}else{
			$('.error_fname').html('');
		}	
        
        if(!selectedRolevalue){
			formIsValid = false;
			$('.error_role').html('<span class="errorspan">Please select the field</span>');
		}else{
			$('.error_role').html('');
		}	

        if(!selectedMenuvalue){
			formIsValid = false;
			$('.error_menu').html('<span class="errorspan">Please select the field</span>');
		}else{
			$('.error_menu').html('');
		}	
		

        if (!email) {
	    	formIsValid = false;		
			$('.error_email').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			var reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		    // var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
		    if (reg.test(email) == false) 
	        {
				formIsValid = false;			
	            $('.error_email').html('<span class="errorspan">Please enter valid email address</span>');
	        }else{
	        	$('.error_email').html('');
	        }
		}

        this.setState({
			errors: errors
		});
		return formIsValid;
	}
	

  render() {


	const {selectedOption,selectedRole,selectedMenu} = this.state;


	console.log(selectedMenu,'selectedMenu');

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="rolemanagement" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit User Roles</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>First Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="fname" onChange={this.handleInputChange} className="form-control" value={this.state.fname}/>
					<div className="error_fname"></div>
				</div>
                <div className="form-group">
					<label>Last Name :</label>
				    <input type="text" name="lname" onChange={this.handleInputChange} className="form-control" value={this.state.lname}/>
				</div>
                <div className="form-group">					
					<label>Role <span class="required" style={{ color: "red" }} > * </span> :</label>
                    <Select 
                        name="role"
                        value={selectedRole?selectedRole:{ value: 'active', label: 'Active'}}
                        options={this.state.rolelist} 
                        isSearchable = {false}
                        onChange={this.handleChangeRole}
                    />
                    <div className="error_role"></div>
	           </div> 
               <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
                    <Select 
                        name="status"
                        value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                        options={lang.common.status_option} 
                        isSearchable = {false}
                        onChange={this.handleChange}
                    />
	           </div> 
				
			</div>
                  
			<div className="form-right">
                <div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="email" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email}/>
					<div className="error_email"></div>
				</div>
                <div className="form-group">
					<label>Contact :</label>
				    <input type="number" name="contact" onChange={this.handleInputChange} className="form-control" value={this.state.contact}/>
				</div>
                <div className="form-group">					
					<label>Module <span class="required" style={{ color: "red" }} > * </span> :</label>
                    <Select 
                        name="menu"
                        value={selectedMenu?selectedMenu:{ value: 'active', label: 'Active'}}
                        options={this.state.modulelist} 
                        isSearchable = {true}
                        onChange={this.handleChangeMenu}
                        isMulti
                    />
                    <div className="error_menu"></div>
	           </div> 
				

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									
					Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Edit));