import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const membershipId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      membershipId: membershipId,
      membershiplistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :membershipId
    };

    axios.post(apiUrl+"insurance/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ membershiplistdata: res.data.membershiplistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Insurance View');
   }
  render() {
  if(this.state.membershiplistdata!==undefined){
    var membershiplistdata = this.state.membershiplistdata;
    var descNewStr = "";
    if(membershiplistdata.description){
      var htmlStr = membershiplistdata.description;
      descNewStr = htmlStr.replace(/(<([^>]+)>)/ig, '');
      descNewStr = descNewStr.replace(/&nbsp;/ig, '');
    }
  }
 
  

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Insurance" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Insurance Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{membershiplistdata.membership_name}</p>
                        </div>
                      </div>

                     
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{descNewStr}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${membershiplistdata.amount}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Period<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(membershiplistdata.period ? membershiplistdata.period + "ly" : "")}</p>
                        </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(membershiplistdata.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                     
                    

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    membershiplistview: state.membershiplistview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewCategoryList: (membershipId) =>{
       dispatch({ type: GET_VIEWCATEGORYLIST,membershipId });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));