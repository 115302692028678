import { SET_COUPONLIST } from '../actions';

const couponlist = (state = [], action) => {
  switch (action.type) {
	case SET_COUPONLIST:
      return [...action.value];  
    default: return state;
  }
}

export default couponlist;
