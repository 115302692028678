import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { GET_CATEGORYLIST,GET_DELETECATEGORY,GET_ACTIVATECATEGORY,  } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      categorylist:'',
      domainlist:[],
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id,
    loggedas: loggedas
    };
    this.props.getCategoryList(qs.stringify(postobject));
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {

      var admin_id = localStorage.getItem('admin_id');

   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
     this.props.getCategoryList(qs.stringify(postobject))
   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  //console.log(postObject);
  this.props.getCategoryList(qs.stringify(postObject));

} 



   componentWillReceiveProps(Props){


    if (Object.keys(Props.categorylist).length > 0) {
      if (Props.categorylist[0]["status"] === "success") {
        this.setState({ totalRecord : Props.categorylist[0].records_count, categorylist: Props.categorylist[0]["categorylist"] });
        this.categorylist();
      }
    }

     if (Props.deletecategory!== this.props.deletecategory) {
      if (Props.deletecategory[0]["status"] === "success") {
         
          $('.success_message').html('<div class="status_sucess"><h3>'+ Props.deletecategory[0]["message"]+'</h3></div>');
           var qs = require('qs');
              var admin_id = localStorage.getItem("admin_id");
              var postobject = {
              activePage: 1,
              search_all: '',
              admin_id: admin_id
              };

              this.props.getCategoryList(qs.stringify(postobject));
           setTimeout(
          function() {
          $('.success_message').html('');
          //window.location.reload(); 
          }
          .bind(this),
          3000
          );


          this.setState({categorylist: Props.deletecategory[0]["categorylist"]}, function() {
            this.categorylist();
          });
       
          }
          
      }

       if(Props.categoryactive !== this.props.categoryactive){
      if (Props.categoryactive[0]["status"] === "success") {

        $('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryactive[0]["message"]+'</h3></div>');
              var qs = require('qs');
              var admin_id = localStorage.getItem("admin_id");
              var postobject = {
              activePage: 1,
              search_all: '',
              admin_id: admin_id
              };

              this.props.getCategoryList(qs.stringify(postobject));


           
           setTimeout(
          function() {
          $('.success_message').html('');
          //window.location.reload(); 
          }
          .bind(this),
          3000
          );

      }
    }
     
   }

   deleteConfirm=(confirmstatus,id)=>{
    if(confirmstatus == 'yes'){
      $('.confirm-action-delete').removeClass('show');
    
      var qs = require('qs');
      var postObject = {
        admin_id : localStorage.getItem("admin_id"),
        id  :id
      };
    this.props.getDeleteCategory(qs.stringify(postObject));
    }else{
      $('.confirm-action-delete').removeClass('show');	
    }
  }

activatecategory(id, status){
  let stat = ''
    if(status == 'Inactive'){
      stat = "A";
    }else{
      stat = "I";
    }
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id,
          status: stat
        };
      this.props.getActivateCategory(qs.stringify(postObject));
}

categorylist() {
  let catImg = '';
  let preview = '';
    var categorylist = this.props.categorylist[0];
    if (categorylist != "undefined" && categorylist != null) {
      if (categorylist.status == "success") {
        if (Object.keys(categorylist).length > 0) {
          const helperlistDetails = categorylist.categorylist.map(
            (categorylist, Index) => {
              let sno = Index+1;
              

               if(categorylist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              if (categorylist.cat_image!== null && categorylist.cat_image!== '') {
              catImg = categoryURL + "/" + categorylist.cat_image;
            preview = <img className="img_class"  src={catImg} alt="" />;
          }
              var istyle = { width: 200};
              return (
                <tr key={categorylist.id}>
      					<td>{sno}</td>
      					<td>{categorylist.cat_name}</td>
      					<td>{categorylist.module}</td>
                <td>{status}</td>
                <td className="actiongroup" >
                    
                    
                     <Link to={{ pathname: '/specialist-view/'+categorylist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/specialist-edit/'+categorylist.id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                    {(status == 'Active'?<Link onClick={this.activatecategory.bind(this, categorylist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activatecategory.bind(this, categorylist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}      
                     <Link onClick={this.deletecategory.bind(this,categorylist.id)} className="" title="Delete"><i className="ft-trash-2" aria-hidden="true"></i></Link>
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  deletecategory(id){
    this.setState({userid : id})
    $('.confirm-action-delete-message').html('Are you sure delete the specialist?');	
    $('.confirm-action-delete').addClass('show');	
   }
  


  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Specialist" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Specialists</h3>
                </div>
               

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                 <div className="add_button">  
                    <a className="" href='/specialist-add'>Add</a>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Name</th>
                  <th>Module</th>
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.categorylist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      <div className="confirm-action-delete">
								<p className="confirm-action-delete-message"></p>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.userid)} className="btn">Yes</a>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
						  </div>

    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    categorylist: state.categorylist,
    deletecategory: state.deletecategory,
    changestatuscategory: state.changestatuscategory,
     categoryactive: state.categoryactive
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryList: (formVal) =>{
      dispatch({ type: GET_CATEGORYLIST, formVal});
   },
   getActivateCategory: (formPayload) =>{
    dispatch({ type: GET_ACTIVATECATEGORY, formPayload });
  },
   getDeleteCategory: (formPayload) =>{
     dispatch({ type: GET_DELETECATEGORY, formPayload });
   },
   
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));