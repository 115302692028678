import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, mediaUrl} from'../Config/Config';
import axios from 'axios';
import moment from 'moment';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    
    const orderid =(typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
        orderid: orderid,
      revenudetails:'',
    };

    // axios.get(apiUrl+"advertisement/listdetail?id="+this.state.adId)
    axios.get(apiUrl+"revenue/listdetail?&id="+orderid).then(res => {
      if(res.data.status === 'success'){
        this.setState({ revenudetails: res.data.detail});
      }
    }); 
  }
   componentDidMount() {
     document.title = PageTitle('Revenu View');
   }
  //  productorderdetails()
  //  {
  //   var productdetails = this.state.productdetails;
  //   if (productdetails != "undefined" && productdetails != null) {
  //     if (productdetails.length > 0) {

  //       if (Object.keys(productdetails).length > 0) {
  //         const helperlistDetails = productdetails.map(
  //           (productdetails, Index) => {
  //             let sno = Index+1;
  //             let loaderimg = '';
  //             let preview = '';            
  //             var  imagepre = productdetails.image;      

  //             if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
  //               loaderimg = mediaUrl + '/product/'+productdetails.image;
  //               preview = <img className="img_class" src={loaderimg} alt="" />;
  //             }else{
  //               // preview = <img className="img_class" src={noimage} alt="" />;
  //               preview = <img className="img_class" src="" alt="" />;
  //             }

  //             return (
  //               <tr key={productdetails.id}>
  //     					<td>{sno}</td>
  //     					<td>{productdetails.p_name}</td>
  //     					<td>{preview}</td>
  //     					<td>${productdetails.single_price}</td>
  //     					<td>{productdetails.quantity}</td>
  //     					<td>${productdetails.total_price}</td>      			    
  //               </tr>
  //             );
  //           }
  //         );
  //         return helperlistDetails;
  //       }
  //     }
  //   }
  //  }
  //  producttotalamount(){
  //   var productdetails = this.state.productdetails;
  //   if (productdetails != "undefined" && productdetails != null) {
  //     if (productdetails.length > 0) {
  //       let tot_amt = 0;
  //       if (Object.keys(productdetails).length > 0) {
           
  //         const helpertotamt = productdetails.map(
  //           (productdetails, Index) => {

  //                tot_amt += parseInt(productdetails.price);
  //           }
  //         );
  //         return tot_amt;
  //       }
  //     }
  //   }
  //  }

  render() {
    const navigate = this.props.navigate;
    // const {revenudetails} = this.state;
  // if(this.state.revenudetails!==undefined){
  //   var revenudetails = this.state.revenudetails;
  // }


  if(this.state.revenudetails!==undefined){
    var revenudetails = this.state.revenudetails;
  }
  
  var revenu=0;
  if(revenudetails.revenue!=null)
  {
    revenu +=parseInt(revenudetails.revenue);
  }
  if(revenudetails.appt_revenue!=null)
  {
    revenu +=parseInt(revenudetails.appt_revenue);
  }
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="ProductHistory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
               </div> 
                <div className="title">
                  <h3>Revenu Detail</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Provider<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{revenudetails.provider_name}</p>
                        </div>
                      </div>
                     {(revenudetails.appt !=null && revenudetails.appt!='')&& <div className="view-details">
                        <div className="view-details-one">
                            <p><b> Appointment ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{revenudetails.appt}</p>
                        </div>
                      </div>}
                      
                     
                      
                      {(revenudetails.appt_revenu !=null && revenudetails.appt_revenu!='')&& <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Appointment Revenu<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> ${revenudetails.appt_revenu}</p>
                        </div>
                      </div>}

                      {(revenudetails.revenu !=null && revenudetails.revenu!='')&& <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Order Revenu<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> ${revenudetails.revenu}</p>
                        </div>
                      </div>}

                     <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Total<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> ${revenu}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Placed On<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>  {moment(revenudetails.created_on).format('MMM DD Y hh:mm A')}</p>
                        </div>
                      </div>


                      


                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Order Detail </b></p>
                        </div>
                       
                      </div> */}
                      {/* <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Product</th>
                  <th>Image</th>
			            <th>Price</th>
			            <th>Quantity</th>
			            <th>Total </th>
                   </tr>
                  </thead>
                  <tbody>
                  {this.productorderdetails()}
                  </tbody>
                </Table>
			        	</div>
                <br></br>
                <div className="view-details-right ">
                        <div className="view-details-right-one">
                            <p><b>Total<span> : ${revenudetails.total_amt}</span> </b></p>
                        </div>
                      
                      </div>
                      
                      </div> */}
                    

                      </div>
                      </div>
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}


export default withRouter(View);