export const GET_LOGINDATA         = "getLoginData";
export const SET_LOGINDATA 		   = "setLoginData";
export const GET_SETTINGDATA 	   = "getSettingData";
export const SET_SETTINGDATA 	   = "setSettingData";
export const GET_UPDATESETTING     = "getUpdateSetting";
export const SET_UPDATESETTING     = "setUpdateSetting";
export const GET_CHANGEPASSWORD     = "getChangePassword";
export const SET_CHANGEPASSWORD     = "setChangePassword";
export const GET_FORGETPASSWORD     = "getForgetPassword";
export const SET_FORGETPASSWORD     = "setForgetPassword";
export const GET_PROFILEDETAILS     = "getProfileDetails";
export const SET_PROFILEDETAILS     = "setProfileDetails";
export const GET_UPDATEPROFILE     = "getUpdateProfile";
export const SET_UPDATEPROFILE     = "setUpdateProfile";

/*Company module*/
export const GET_ADDCOMPANY         = "getAddCompany";
export const SET_ADDCOMPANY         = "setAddCompany";
export const GET_EDITCOMPANY        = "getEditCompany";
export const SET_EDITCOMPANY        = "setEditCompany";
export const GET_COMPANYLIST        = "getCompanyList";
export const SET_COMPANYLIST        = "setCompanyList";
export const GET_COMPANYDETAILLIST  = "getCompanyDetailList";
export const SET_COMPANYDETAILLIST  = "setCompanyDetailList";
export const GET_DELETECOMPANY      = "getDeleteCompany";
export const SET_DELETECOMPANY      = "setDeleteCompany";
/*end*/

export const GET_EXPORTUSER         = "getExportuser";
export const SET_EXPORTUSER         = "setExportuser";

/*user module*/
export const GET_ADDUSER         = "getAddUser";
export const SET_ADDUSER         = "setAddUser";
export const GET_EDITUSER        = "getEditUser";
export const SET_EDITUSER        = "setEditUser";
export const GET_USERLIST        = "getUserList";
export const SET_USERLIST        = "setUserList";
export const GET_DELETEUSER      = "getDeleteUser";
export const SET_DELETEUSER      = "setDeleteUser";

export const GET_IMPORTUSER       = "getImportUser";
export const SET_IMPORTUSER       = "setImportUser";

export const GET_ACTIVATEUSER        = "getActivateUser";
export const SET_ACTIVATEUSER        = "setActivateUser";

export const GET_USERDETAILLIST      = "getUserDetailList";
export const SET_USERDETAILLIST      = "setUserDetailList";
export const GET_VIEWUSERLIST      = "getViewUserList";
export const SET_VIEWUSERLIST      = "setViewUserList";

/*end*/


/*get fonts*/

export const GET_FONTLIST            = "getFontList";
export const SET_FONTLIST            = "setFontList";

/*get font name for settings*/
export const GET_FONTNAME            = "getFontName";
export const SET_FONTNAME            = "setFontName";

export const GET_ADDFONT             = "getAddFont";
export const SET_ADDFONT             = "setAddFont";

export const GET_DELETEFONT           = "getDeleteFont";
export const SET_DELETEFONT           = "setDeleteFont";

export const GET_EDITFONT        = "getEditFont";
export const SET_EDITFONT        = "setEditFont";
export const GET_FONTDETAILLIST  = "getFontDetailList";
export const SET_FONTDETAILLIST  = "setFontDetailList";


/*Template Module*/

export const GET_TEMPLATELIST        = "getTemplateList";
export const SET_TEMPLATELIST        = "setTemplateList";
export const GET_TEMPLATEDETAIL        = "getTemplateDetail";
export const SET_TEMPLATEDETAIL        = "setTemplateDetail";
export const GET_TEMPLATEADD           = "getTemplateAdd";
export const SET_TEMPLATEADD           = "setTemplateAdd";
export const GET_TEMPLATEEDIT           = "getTemplateEdit";
export const SET_TEMPLATEEDIT           = "setTemplateEdit";
export const GET_TEMPLATEDELETE        = "getTemplateDelete";
export const SET_TEMPLATEDELETE        = "setTemplateDelete";


/*get a userlist by pluginId*/

export const GET_USERLISTBYPLUGIN        = "getUserListByPlugin";
export const SET_USERLISTBYPLUGIN        = "setUserListByPlugin";


/*Payment module */
export const GET_PAYMENTLIST     = "getPaymentList";
export const SET_PAYMENTLIST     = "setPaymentList";
export const GET_PAYMENTVIEWLIST   = "getPaymentViewList";
export const SET_PAYMENTVIEWLIST   = "setPaymentViewList";


/*Dashboard module */
export const GET_DASHBOARDLIST     = "getDashboardList";
export const SET_DASHBOARDLIST     = "setDashboardList";


/*end*/


/*Cms*/

export const GET_ADDCMS              = "getAddCms";
export const SET_ADDCMS              = "setAddCms"; 
export const GET_EDITCMS             = "getEditCms";
export const SET_EDITCMS             = "setEditCms";
export const GET_CMSLIST             = "getCmsList";
export const SET_CMSLIST             = "setCmsList";

export const GET_CMSDETAIL           = "getCmsDetail";
export const SET_CMSDETAIL           = "setCmsDetail";

export const GET_DELETECMS           = "getDeleteCms";
export const SET_DELETECMS           = "setDeleteCms";

export const GET_ACTIVATECMS         = "getActivateCms";
export const SET_ACTIVATECMS         = "setActivateCms";


/*Category module*/

export const GET_ADDCATEGORY         = "getAddCategory";
export const SET_ADDCATEGORY         = "setAddCategory";
export const GET_EDITCATEGORY        = "getEditCategory";
export const SET_EDITCATEGORY        = "setEditCategory";
export const GET_CATEGORYLIST        = "getCategoryList";
export const SET_CATEGORYLIST        = "setCategoryList";
export const GET_DELETECATEGORY     = "getDeleteCategory";
export const SET_DELETECATEGORY     = "setDeleteCategory";

export const GET_IMPORTCATEGORY       = "getImportCategory";
export const SET_IMPORTCATEGORY       = "setImportCategory";

export const GET_ACTIVATECATEGORY        = "getActivateCategory";
export const SET_ACTIVATECATEGORY        = "setActivateCategory";

export const GET_CATEGORYDETAILLIST      = "getCategoryDetailList";
export const SET_CATEGORYDETAILLIST      = "setCategoryDetailList";
export const GET_VIEWCATEGORYLIST        = "getViewCategoryList";
export const SET_VIEWCATEGORYLIST        = "setViewCategoryList";



/*Product module*/

export const GET_ADDPRODUCT         = "getAddProduct";
export const SET_ADDPRODUCT         = "setAddProduct";
export const GET_EDITPRODUCT        = "getEditProduct";
export const SET_EDITPRODUCT       = "setEditProduct";
export const GET_PRODUCTLIST        = "getProductList";
export const SET_PRODUCTLIST        = "setProductList";
export const GET_DELETEPRODUCT  = "getDeleteProduct";
export const SET_DELETEPRODUCT     = "setDeleteProduct";

export const GET_IMPORTPRODUCT       = "getImportProduct";
export const SET_IMPORTPRODUCT      = "setImportProduct";

export const GET_ACTIVATEPRODUCT       = "getActivateProduct";
export const SET_ACTIVATEPRODUCT       = "setActivateProduct";

export const GET_PRODUCTDETAILLIST      = "getProductDetailList";
export const SET_PRODUCTDETAILLIST      = "setProductDetailList";
export const GET_VIEWPRODUCTLIST        = "getViewProductList";
export const SET_VIEWPRODUCTLIST        = "setViewProductList";




/*Packages*/

export const GET_ADDPACKAGE              = "getAddPackage";
export const SET_ADDPACKAGE             = "setAddPackage"; 
export const GET_EDITPACKAGE             = "getEditPackage";
export const SET_EDITPACKAGE            = "setEditPackage";
export const GET_PACKAGELIST             = "getPackageList";
export const SET_PACKAGELIST             = "setPackageList";

export const GET_PACKAGEDETAIL           = "getPackageDetail";
export const SET_PACKAGEDETAIL           = "setPackageDetail";

export const GET_DELETEPACKAGE           = "getDeletePackage";
export const SET_DELETEPACKAGE           = "setDeletePackage";

export const GET_ACTIVATEPACKAGE         = "getActivatePackage";
export const SET_ACTIVATEPACKAGE         = "setActivatePackage";




/*Formbuilder*/

export const GET_ADDFORM              = "getAddForm";
export const SET_ADDFORM              = "setAddForm"; 
export const GET_EDITFORM             = "getEditForm";
export const SET_EDITFORM            = "setEditForm";
export const GET_FORMLIST             = "getFormList";
export const SET_FORMLIST             = "setFormList";

export const GET_FORMDETAIL           = "getFormDetail";
export const SET_FORMDETAIL           = "setFormDetail";

export const GET_DELETEFORM           = "getDeleteForm";
export const SET_DELETEFORM           = "setDeleteForm";

export const GET_ACTIVATEFORM         = "getActivateForm";
export const SET_ACTIVATEFORM         = "setActivateForm";

/*Form entries*/


export const GET_FORMENTRIESLIST             = "getFormEntriesList";
export const SET_FORMENTRIESLIST             = "setFormEntriesList";

export const GET_FORMENTRYDETAIL            = "getFormEntryDetail";
export const SET_FORMENTRYDETAIL            = "setFormEntryDetail";


/*get coupon name*/


export const GET_COUPONLIST            = "getCouponList";
export const SET_COUPONLIST            = "setCouponList";

export const GET_COUPONDETAILLIST      = "getCouponDetailList";
export const SET_COUPONDETAILLIST      = "setCouponDetailList";

export const GET_ADDCOUPON             = "getAddCoupon";
export const SET_ADDCOUPON             = "setAddCoupon";

export const GET_EDITCOUPON        = "getEditCoupon";
export const SET_EDITCOUPON        = "setEditCoupon";


/* cms content */

export const GET_PLUGINCONTENT	        = "getPluginContent";
export const SET_PLUGINCONTENT 	        = "setPluginContent";
export const GET_UPDATEPLUGINCONTENT    = "getUpdatePluginContent";
export const SET_UPDATEPLUGINCONTENT    = "setUpdatePluginContent";