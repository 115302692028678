import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";

import moment from 'moment';
import {
    Row,
    Col,
    Modal,
    Table,
    OverlayTrigger,
    ButtonToolbar,
    Tooltip,
    Button
  } from "react-bootstrap"; 
  
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,productURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';
import noimage from '../../common/images/no-image.png';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const orderid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
        orderid: orderid,
      productorderlistdata:'',
      productdetails:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :orderid
    };

    axios.post(apiUrl+"ProductBookingHistory/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ productorderlistdata: res.data.order,productdetails:res.data.products});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Category View');
   }
   productorderdetails()
   {
    var productdetails = this.state.productdetails;
    if (productdetails != "undefined" && productdetails != null) {
      if (productdetails.length > 0) {

        if (Object.keys(productdetails).length > 0) {
          const helperlistDetails = productdetails.map(
            (productdetails, Index) => {
              let sno = Index+1;
              let loaderimg = '';
              let preview = '';            
              var  imagepre = productdetails.image;      

              if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
                loaderimg = productURL + '/'+productdetails.image;
                preview = <img className="img_class" src={loaderimg} alt="" />;
              }else{
                // preview = <img className="img_class" src={noimage} alt="" />;
                preview = <img className="img_class" src="" alt="" />;
              }

              return (
                <tr key={productdetails.id}>
      					<td>{sno}</td>
      					<td>{productdetails.p_name}</td>
      					<td>{preview}</td>
      					<td>{productdetails.product_catname}</td>
      					<td>${productdetails.single_price}</td>
      					<td>{productdetails.quantity}</td>
      					<td>${productdetails.total_price}</td>
      			    
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      }
    }
   }
   producttotalamount(){
    var productdetails = this.state.productdetails;
    if (productdetails != "undefined" && productdetails != null) {
      if (productdetails.length > 0) {
        let tot_amt = 0;
        if (Object.keys(productdetails).length > 0) {
           
          const helpertotamt = productdetails.map(
            (productdetails, Index) => {

                 tot_amt += parseInt(productdetails.price);
            }
          );
          return tot_amt;
        }
      }
    }
   }
  render() {
  if(this.state.productorderlistdata!==undefined){
    var productorderlistdata = this.state.productorderlistdata;
  }
  

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="ProductBookingHistory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Product Order History View</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.username}</p>
                        </div>
                      </div>
                     {(productorderlistdata.email !=null && productorderlistdata.email!='')&& <div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.email}</p>
                        </div>
                      </div>}
                      {(productorderlistdata.mobileno !=null && productorderlistdata.mobileno!='')&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Mobile<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.mobileno}</p>
                        </div>
                      </div>}
                      
                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Specialist<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.specialist_name}</p>
                        </div>
                      </div> */}

                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.catname}</p>
                        </div>
                      </div> */}
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Transaction ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> {productorderlistdata.tansaction_id}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Paid Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> ${productorderlistdata.total_amt}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Ordered On<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>  {moment(productorderlistdata.created_on).format('MMM DD Y hh:mm A')}</p>
                        </div>
                      </div>


                      


                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Order Detail </b></p>
                        </div>
                       
                      </div>
                      <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>product Name</th>
                  <th>Image</th>
			            <th>Category</th>
			            <th>Price</th>
			            <th>Quantity</th>
			            <th>Total Price</th>
                   </tr>
                  </thead>
                  <tbody>
                  {this.productorderdetails()}
                  </tbody>
                </Table>
			        	</div>
                <br></br>
                <div className="view-details-right ">
                        <div className="view-details-right-one">
                            <p><b>Paid Amount<span> : ${productorderlistdata.total_amt}</span> </b></p>
                        </div>
                      
                      </div>
                      
                      </div>
                    

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    categorylistview: state.categorylistview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewCategoryList: (categoryid) =>{
       dispatch({ type: GET_VIEWCATEGORYLIST,categoryid });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));