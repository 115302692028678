// QueryContext.js
import React, { createContext, Component } from 'react';

// Create the context
const QueryContext = createContext();

export class QueryProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ehrpanel: null,
        };
    }

    componentDidMount() {
        // Parse the query string from the URL to get 'ehrpanel'
        const params = new URLSearchParams(window.location.search);
        const ehrpanel = params.get('ehrpanel');
        if (ehrpanel) {
            this.setState({ ehrpanel });
        }
    }

    render() {
        return (
            <QueryContext.Provider value={this.state}>
                {this.props.children}
            </QueryContext.Provider>
        );
    }
}

// Export the context to be used in other components
export default QueryContext;
